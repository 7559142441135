import { environment } from '@environments/leap/environment';
import Link from '@leap-common/interfaces/link.interface';

export const CHAT_ICON: string =
    environment.app === 'd3' ? 'fa-kit fa-eve-chatbot' : 'fa-solid fa-message-bot';
export const CHAT_CLOSE_ICON: string = 'fa-solid fa-circle-xmark close-icon';

export const HEADER_TITLE: string = environment.app === 'd3' ? 'Eve' : 'Assistant Name';
export const HEADER_SUBTITLE: string =
    environment.app === 'd3' ? 'Virtual assistant supported by D3' : 'Your Virtual assistant';

export const INPUT_MAX_LENGTH: number = 512;
export const INPUT_PLACEHOLDER: string = 'Enter your question here...';
export const INPUT_SEND_ICON: string = 'fa-solid fa-paper-plane';
export const INPUT_COUNT_TEXT: string = `%s / ${INPUT_MAX_LENGTH} characters`;

export const LINKS_INFO: Link[] = [
    {
        text: 'Release notes',
        url: 'help-center/release-notes',
        shouldOpenInNewTab: true,
    },
    {
        text: 'User guide',
        url: 'help-center/user-guide',
        shouldOpenInNewTab: true,
    },
    {
        text: 'FAQs',
        url: 'help-center/faqs',
        shouldOpenInNewTab: true,
    },
    {
        text: 'Credits',
        url: 'help-center/credits',
        shouldOpenInNewTab: true,
    },
    {
        text: 'Privacy policy',
        url: 'help-center/privacy-policy',
        shouldOpenInNewTab: true,
    },
    {
        text: 'Terms of service',
        url: 'help-center/terms-of-service',
        shouldOpenInNewTab: true,
    },
    {
        text: 'Projects',
        url: 'projects',
        shouldOpenInNewTab: true,
    },
    {
        text: 'CMP',
        url: 'cow-milk-profiler',
        shouldOpenInNewTab: true,
    },
    {
        text: 'Cow Milk Profiler',
        url: 'cow-milk-profiler',
        shouldOpenInNewTab: true,
    },
    {
        text: 'Dairy Profiler',
        url: 'dairy-profiler',
        shouldOpenInNewTab: true,
    },
];

export const DOM_CHANGES_OBSERVER_OPTIONS: MutationObserverInit = {
    attributes: false,
    childList: true,
    subtree: false,
};
