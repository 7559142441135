/** third-party imports */
import { Injectable } from '@angular/core';
import { uniq } from 'lodash';

/** Services - Parsers */
import { ArrayHandlerService } from '@leap-common/services/array-handler.service';
import { InsightParser } from '@apps/leap/src/app/shared/parsers/insight.parser';
import { MetadataParser } from '../../metadata/parsers/metadata.parser';

/** Interfaces - Types - Enums */
import PaginatedResultsRestApi from '@leap-common/rest-api-interfaces/paginated-results.rest.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import PaginatedCompoundsRestApi from '../rest-api-interfaces/paginated-compounds.rest.interface';
import PaginatedCompounds from '../interfaces/paginated-compounds.interface';
import CompoundRestApi from '../rest-api-interfaces/compound.rest.interface';
import Compound from '../interfaces/compound.interface';
import InsightRestApi from '../rest-api-interfaces/insight.rest.interface';
import Insight from '../interfaces/insight.interface';
import InsightCategories from '@apps/leap/src/app/shared/types/insight-categories.type';
import TargetRestApi from '../rest-api-interfaces/target.rest.interface';
import Target from '../interfaces/target.interface';
import PaginatedInsights from '../interfaces/paginated-insights.interface';
import PaginatedInsightsRestApi from '../rest-api-interfaces/paginated-insights.rest.interface';
import InsightCategoriesRestApi from '@apps/leap/src/app/shared/rest-api-types/insight-categories.rest.type';
import SortingOrder from '@leap-common/enums/sorting-order.enum';
import CustomIngredientRestApi from '../rest-api-interfaces/custom-ingredient.rest.interface';
import CustomIngredient from '../interfaces/custom-ingredient.interface';

@Injectable()
export class IngredientProfilerParser {
    constructor(
        private insightParser: InsightParser,
        private metadataParser: MetadataParser,
        private arrayHandlerService: ArrayHandlerService,
    ) {}

    parseCustomIngredient(customIngredient: CustomIngredientRestApi): CustomIngredient {
        return {
            id: customIngredient.ingredientUid,
            name: customIngredient.name,
            foundInMultipleCompounds: customIngredient.anyComponentFoundInSynonyms,
        };
    }

    parsePaginatedCompounds(
        paginatedCompounds: PaginatedCompoundsRestApi,
        ingredientId?: string,
    ): PaginatedCompounds {
        return {
            filterCounts: this.insightParser.parseFilterCounts(paginatedCompounds.counts),
            results: paginatedCompounds.results
                ? this.parseCompounds(paginatedCompounds.results, ingredientId)
                : [],
            pageIndex: paginatedCompounds.pageIndex,
            pageSize: paginatedCompounds.pageSize,
            total: paginatedCompounds.total,
            displayingCompounds: paginatedCompounds.totalDisplayingComponents,
            displayingInsights: paginatedCompounds.totalDisplayingInsights,
            oldestOccurrence: paginatedCompounds.totalOldestPublicationDate,
            newestOccurrence: paginatedCompounds.totalNewestPublicationDate,
            minMoleculeWeight: paginatedCompounds.smallestMolecularWeight,
            maxMoleculeWeight: paginatedCompounds.largestMolecularWeight,
            minCowMilkConcentration: paginatedCompounds.smallestCowMilkConcentration,
            maxCowMilkConcentration: paginatedCompounds.largestCowMilkConcentration,
            minSourceConcentration: paginatedCompounds.smallestSourceConcentration,
            maxSourceConcentration: paginatedCompounds.largestSourceConcentration,
            shouldShowSourceConcentration: this.metadataParser.parseShouldShowConcentration(
                paginatedCompounds.concentrationCuis,
                ingredientId,
            ),
            preferences: paginatedCompounds.preferences,
            isCustomIngredient: paginatedCompounds.isUserDefined,
        };
    }

    parseCompounds(compounds: CompoundRestApi[], ingredientId?: string): Compound[] {
        return compounds.map((compound: CompoundRestApi) =>
            this.parseCompound(compound, ingredientId),
        );
    }

    parseCompound(compound: CompoundRestApi, ingredientId?: string): Compound {
        const sortedCategories: InsightCategoriesRestApi =
            this.arrayHandlerService.sortObjectByValues(compound.categories);

        return {
            rankingIndex: compound.index,
            id: compound.uid,
            name: compound.name,
            officialName: compound.officialName,
            categoryPerSubcategory: sortedCategories || {},
            categories: this.insightParser.parseCategories(sortedCategories),
            subcategories: this.insightParser.parseSubcategories(compound.categories),
            synonyms: this.insightParser.parseSynonyms(compound.synonyms),
            definition: compound.definition,
            tags: this.insightParser.parseTags(compound.tags?.Tags),
            healthLabels: this.insightParser.parseHealthLabels(compound.healthAreas),
            labs: this.insightParser.parseLabs(compound.tags?.['UCD DMD lab']),
            studyTypes: compound.typesOfStudy || [],
            koOriginDatabases: compound.koSourceDBs,
            molecules: this.insightParser.parseMolecules(
                compound.tags?.['Molecule classification'],
            ),
            molecularWeight: compound.molecularWeight,
            cowMilkConcentration: compound.cowMilkConcentration,
            totalTargets: compound.totalTargets,
            totalHealthLabels: compound.totalHealthAreas,
            totalCoOccurrences: compound.totalCooccurrences,
            totalArticles: compound.totalArticles,
            isIdentified: Boolean(compound.identifiedByIP),
            isReportEnabled: Boolean(compound.reportAllowed),
            proteinOrigins: this.insightParser.parseProteinOrigins(compound.sourceProtein),
            sourceConcentration: this.metadataParser.parseConcentrationMeasurement(
                compound.concentrations?.[ingredientId],
            ),
        };
    }

    parsePaginatedInsights(paginatedInsights: PaginatedInsightsRestApi): PaginatedInsights {
        return {
            results: paginatedInsights.results ? this.parseInsights(paginatedInsights.results) : [],
            pageIndex: paginatedInsights.pageIndex,
            pageSize: paginatedInsights.pageSize,
            total: paginatedInsights.total,
            displayingInsights: paginatedInsights.totalDisplayingInsights,
        };
    }

    parseInsights(insights: InsightRestApi[]): Insight[] {
        return insights.map((insight: InsightRestApi) => this.parseInsight(insight));
    }

    parseInsight(insight: InsightRestApi): Insight {
        const sortedSourceCategories: InsightCategories =
            this.arrayHandlerService.sortObjectByValues(insight.sourceCategories);
        const sortedTargetCategories: InsightCategories =
            this.arrayHandlerService.sortObjectByValues(insight.targetCategories);

        return {
            id: `${insight.sourceUid}_${insight.targetUid}`,
            sourceId: insight.sourceUid,
            sourceName: insight.sourceName,
            sourceCategories: sortedSourceCategories || {},
            sourceMolecules: insight?.sourceTags?.['Molecule classification'] || [],
            sourceTags: this.insightParser.parseTags(insight.sourceTags?.Tags),
            targetId: insight.targetUid,
            targetName: insight.targetName,
            targetCategories: sortedTargetCategories || {},
            targetLabs: insight?.targetTags?.['UCD DMD lab'] || [],
            targetMolecules: insight?.targetTags?.['Molecule classification'] || [],
            targetTags: this.insightParser.parseTags(insight.targetTags?.Tags),
            weightRaw: insight.weightRawDirect,
            totalScore: this.insightParser.parseScore(insight.totalScore),
            isNovel: insight.isNovel,
            associationOrigins: this.insightParser.parseAssociationOrigins(
                insight.associationOrigins,
            ),
            relationshipType: this.insightParser.parseRelationshipType(insight.relationshipType),
            isRelationshipTypePredicted: insight.isRelationshipTypePredicted,
            relationshipTypeProbability: this.insightParser.parseProbability(
                insight.relationshipTypeProbability,
            ),
            strength: this.insightParser.parseScoreClassification(insight.associationStrength),
            studyTypes: insight.typesOfStudy || [],
            journals: this.insightParser.parseJournals(insight.journals),
            articlesCount: this.insightParser.parseArticlesCount(insight.noOfArticles),
            healthLabels: this.insightParser.parseHealthLabels(insight.healthAreas),
            koOriginDatabases: insight.koSourceDBs,
            synonyms: this.insightParser.parseSynonyms(insight.synonyms),
            type: insight.type,
            ingredientConnections: insight.ingredientConnections,
            isLinkDirect: insight.direct,
        };
    }

    parsePaginatedTargets(
        paginatedTargets: PaginatedResultsRestApi<TargetRestApi>,
    ): PaginatedResults<Target> {
        return {
            results: paginatedTargets.results ? this.parseTargets(paginatedTargets.results) : [],
            pageIndex: paginatedTargets.pageIndex,
            pageSize: paginatedTargets.pageSize,
            total: paginatedTargets.total,
        };
    }

    parseTargets(targets: TargetRestApi[]): Target[] {
        return targets.map((target: TargetRestApi) => this.parseTarget(target));
    }

    parseTarget(target: TargetRestApi): Target {
        const sortedTargetCategories: InsightCategories =
            this.arrayHandlerService.sortObjectByValues(target.targetCategories);

        return {
            id: `${target.sourceUid}_${target.targetUid}`,
            sourceId: target.sourceUid,
            sourceName: target.sourceName,
            sourceCategories: target.sourceCategories,
            sourceTags: this.insightParser.parseTags(target.sourceTags?.Tags),
            targetId: target.targetUid,
            targetName: target.targetName,
            targetCategories: sortedTargetCategories || {},
            targetTags: this.insightParser.parseTags(target.targetTags?.Tags),
            totalScore: this.insightParser.parseScore(target.totalScore),
            weightRaw: target.weightRawDirect,
            relationshipType: this.insightParser.parseRelationshipType(target.relationshipType),
            studyTypes: target.typesOfStudy || [],
            journals: target.journals && this.insightParser.parseJournals(target.journals),
            articlesCount:
                target.noOfArticles && this.insightParser.parseArticlesCount(target.noOfArticles),
            healthLabels:
                target.healthAreas && this.insightParser.parseHealthLabels(target.healthAreas),
            type: 'open',
            ingredientConnections: target.ingredientConnections,
            isLinkDirect: target.direct,
            associationOrigins: this.insightParser.parseAssociationOrigins(
                target.associationOrigins,
            ),
            koOriginDatabases: target.koSourceDBs,
        };
    }
}
