export const EMPTY_STRING: string = '';
export const WHITESPACE: string = ' ';
export const DOT: string = '.';
export const ELLIPSIS: string = '...';
export const DASHES: string = '----';
export const LOWER_THAN: string = '<';
export const DEFAULT_TRUNCATION_LIMIT: number = 30;
export const MINIMUM: string = 'Min';
export const MAXIMUM: string = 'Max';
export const MINIMUM_DISPLAYED_VALUE: number = 0.01;
export const CURRENT_YEAR: number = new Date().getFullYear();
export const US_LOCALE: string = 'en-US';
export const NOT_AVAILABLE: string = 'n/a';
export const BETA_TEXT: string = 'BETA';
export const CANCEL_TEXT: string = 'Cancel';
export const FROZEN_COLUMN_CLASS_NAME: string = '.datatable-row-left';
export const MODAL_CLOSE_BUTTON_CLASS_NAME: string = '.button-close';
export const NO_OP_CALLBACK: () => void = (): void => {};
export const TOOLTIP_OPEN_DELAY: number = 500;
export const FORM_CLEAR_BUTTON_CLASS: string = 'fa-xmark';
export const FORM_CIRCULAR_CLEAR_BUTTON_CLASS: string = 'fa-circle-xmark';
