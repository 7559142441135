/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as actions from './patents.actions';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { PatentsState } from './patents-state.interface';
import PaginatedPatents from './interfaces/paginated-patents.interface';
import Patent from './interfaces/patent.interface';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export const adapter: EntityAdapter<Patent> = createEntityAdapter<Patent>();

export const initialState: PatentsState = adapter.getInitialState({
    oldestPublicationDate: null,
    newestPublicationDate: null,
    filteredTotal: null,
    errors: [],
    loading: false,
    loaded: false,
    blob: null,
});

const patentsReducer: ActionReducer<PatentsState, Action> = createReducer(
    initialState,
    on(actions.getPatentsRequest, (state: PatentsState) => ({
        ...state,
        loading: true,
        loaded: false,
    })),
    on(
        actions.getPatentsSuccess,
        (state: PatentsState, { paginatedPatents }: { paginatedPatents: PaginatedPatents }) =>
            adapter.setAll(paginatedPatents.results, {
                ...state,
                oldestPublicationDate: paginatedPatents.oldestPublicationDate,
                newestPublicationDate: paginatedPatents.newestPublicationDate,
                filteredTotal: paginatedPatents.total,
                loading: false,
                loaded: true,
            }),
    ),
    on(
        actions.getPatentsFailure,
        (state: PatentsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            loading: false,
            loaded: false,
        }),
    ),
    on(actions.downloadPatentsRequest, (state: PatentsState) => ({
        ...state,
        blob: null as Blob,
    })),
    on(actions.downloadPatentsSuccess, (state: PatentsState, { blob }: { blob: Blob }) => ({
        ...state,
        blob,
    })),
    on(
        actions.downloadPatentsFailure,
        (state: PatentsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(actions.clearPatents, (state: PatentsState) =>
        adapter.removeAll({
            ...state,
            loading: false,
            loaded: false,
        }),
    ),
    on(actions.clearNextError, (state: PatentsState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (state: PatentsState | undefined, action: Action): PatentsState =>
    patentsReducer(state, action);

// selectors
export const getPatents: (state: PatentsState) => Patent[] = adapter.getSelectors().selectAll;
export const getPublicationDateRange: (state: PatentsState) => [number, number] = (
    state: PatentsState,
) => [state.oldestPublicationDate, state.newestPublicationDate];
export const getFilteredTotal: (state: PatentsState) => number = (state: PatentsState) =>
    state.filteredTotal;
export const getLoading: (state: PatentsState) => boolean = (state: PatentsState) => state.loading;
export const getLoaded: (state: PatentsState) => boolean = (state: PatentsState) => state.loaded;
export const getErrors: (state: PatentsState) => ErrorResponse[] = (state: PatentsState) =>
    state.errors;
export const getBlob: (state: PatentsState) => Blob = (state: PatentsState) => state.blob;
