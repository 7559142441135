/** third-party imports */
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

/** custom imports */
import { CompoundReportService } from './services/compound-report.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as actions from './compound-report.actions';
import Overview from './interfaces/overview.interface';
import RelationshipGroup from '../ingredient-profiler/enums/relationship-group.enum';
import RelationshipGroups from './interfaces/relationship-groups.interface';
import RelationshipGroupSummaries from './interfaces/relationship-group-summaries.interface';
import HealthLabel from './interfaces/health-label.interface';
import StudyTypes from './interfaces/study-types.interface';
import StudyType from './interfaces/study-type.interface';
import Summary from '../report/interfaces/summary.interface';

@Injectable()
export class CompoundReportEffects {
    constructor(private actions$: Actions, private compoundReportService: CompoundReportService) {}

    getOverview$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getOverviewRequest),
            switchMap(({ id }: { id: string }) =>
                this.compoundReportService.getOverview(id).pipe(
                    map(({ overview, total }: { overview: Overview; total: number }) =>
                        actions.getOverviewSuccess({ overview, total }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getOverviewFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getRelationshipGroups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getRelationshipGroupsRequest),
            switchMap(({ id }: { id: string }) =>
                this.compoundReportService.getRelationshipGroups(id).pipe(
                    map((relationshipGroups: RelationshipGroups) =>
                        actions.getRelationshipGroupsSuccess({ relationshipGroups }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getRelationshipGroupsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getRelationshipGroupSummaries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getRelationshipGroupSummariesRequest),
            mergeMap(
                ({ id, relationshipGroup }: { id: string; relationshipGroup: RelationshipGroup }) =>
                    this.compoundReportService
                        .getRelationshipGroupSummaries(id, relationshipGroup)
                        .pipe(
                            map((relationshipGroupSummaries: RelationshipGroupSummaries) =>
                                actions.getRelationshipGroupSummariesSuccess({
                                    relationshipGroupSummaries,
                                }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getRelationshipGroupSummariesFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getHealthLabels$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getHealthLabelsRequest),
            switchMap(({ id }: { id: string }) =>
                this.compoundReportService.getHealthLabels(id).pipe(
                    map((healthLabels: HealthLabel[]) =>
                        actions.getHealthLabelsSuccess({ healthLabels }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getHealthLabelsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getHealthLabelSummary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getHealthLabelSummaryRequest),
            mergeMap(({ id, healthLabel }: { id: string; healthLabel: string }) =>
                this.compoundReportService.getHealthLabelSummary(id, healthLabel).pipe(
                    map((summary: Summary) => actions.getHealthLabelSummarySuccess({ summary })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getHealthLabelSummaryFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getStudyTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getStudyTypesRequest),
            switchMap(({ id }: { id: string }) =>
                this.compoundReportService.getStudyTypes(id).pipe(
                    map((studyTypes: StudyTypes) => actions.getStudyTypesSuccess({ studyTypes })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getStudyTypesFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getStudyType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getStudyTypeRequest),
            mergeMap(({ id, name }: { id: string; name: string }) =>
                this.compoundReportService.getStudyType(id, name).pipe(
                    map((studyType: StudyType) => actions.getStudyTypeSuccess({ studyType })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getStudyTypeFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
