/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import SortingField from '@apps/leap/src/app/shared/enums/sorting-field.enum';
import SortingOrder from '@leap-common/enums/sorting-order.enum';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import ArticleInfo from './interfaces/article-info.interface';
import { ArticlesState } from './articles-state.interface';
import {
    getArticlesInfoRequest,
    selectArticleInfo,
    clearSelectedArticleInfo,
    clearArticlesInfo,
    getArticlesRequest,
    downloadArticlesRequest,
    getTermArticlesRequest,
    downloadTermArticlesRequest,
    downloadFullArticleRequest,
    clearTermArticles,
    clearCounts,
    clearNextError,
} from './articles.actions';
import {
    getSelectedArticleInfo,
    getArticles,
    getArticlesInfo,
    getCountsPerStudyType,
    getCountsPerJournal,
    getCountsPerOrigin,
    getCountsPerRelationshipType,
    getCountsPerPublicationDate,
    getFilteredTotal,
    getTermArticles,
    getTermArticlesLoading,
    getTermArticlesLoaded,
    getTermArticlesFilteredTotal,
    getErrors,
    getLoading,
    getLoaded,
    getBlob,
    getTermArticlesBlob,
    getFullArticleBlob,
} from './articles.selectors';
import Article from './interfaces/article.interface';
import Discovery from '@apps/leap/src/app/shared/types/discovery.type';
import FilterCounts from '@apps/leap/src/app/shared/modules/filters/interfaces/filter-counts.interface';
import BookmarkIds from '@leap-store/core/src/lib/data/bookmarks/interfaces/bookmark-ids.interface';

@Injectable()
export class ArticlesFacade {
    articles$: Observable<Article[]> = this.store.pipe(select(getArticles));
    articlesInfo$: Observable<ArticleInfo[]> = this.store.pipe(select(getArticlesInfo));
    countsPerStudyType$: Observable<Record<string, FilterCounts>> = this.store.pipe(
        select(getCountsPerStudyType),
    );
    countsPerJournal$: Observable<Record<string, FilterCounts>> = this.store.pipe(
        select(getCountsPerJournal),
    );
    countsPerOrigin$: Observable<Record<string, FilterCounts>> = this.store.pipe(
        select(getCountsPerOrigin),
    );
    countsPerRelationshipType$: Observable<Record<string, FilterCounts>> = this.store.pipe(
        select(getCountsPerRelationshipType),
    );
    countsPerPublicationDate$: Observable<Record<string, FilterCounts>> = this.store.pipe(
        select(getCountsPerPublicationDate),
    );
    filteredTotal$: Observable<number> = this.store.pipe(select(getFilteredTotal));
    selectedArticleInfo$: Observable<ArticleInfo> = this.store.pipe(select(getSelectedArticleInfo));
    termArticles$: Observable<Article[]> = this.store.pipe(select(getTermArticles));
    termArticlesLoading$: Observable<boolean> = this.store.pipe(select(getTermArticlesLoading));
    termArticlesLoaded$: Observable<boolean> = this.store.pipe(select(getTermArticlesLoaded));
    termArticlesFilteredTotal$: Observable<number> = this.store.pipe(
        select(getTermArticlesFilteredTotal),
    );
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    loading$: Observable<boolean> = this.store.pipe(select(getLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getLoaded));
    blob$: Observable<Blob> = this.store.pipe(select(getBlob));
    termArticlesBlob$: Observable<Blob> = this.store.pipe(select(getTermArticlesBlob));
    fullArticleBlob$: Observable<Blob> = this.store.pipe(select(getFullArticleBlob));

    constructor(private store: Store<ArticlesState>) {}

    getArticlesInfo(
        ids: [string, string, string?][],
        discovery: Discovery,
        isSelected: boolean = false,
        bookmarkIds?: BookmarkIds,
    ): void {
        this.store.dispatch(getArticlesInfoRequest({ ids, isSelected, discovery, bookmarkIds }));
    }

    selectArticleInfo(sourceId: string, targetId: string, intermediateId?: string): void {
        this.store.dispatch(selectArticleInfo({ sourceId, targetId, intermediateId }));
    }

    clearSelectedArticleInfo(): void {
        this.store.dispatch(clearSelectedArticleInfo());
    }

    getArticles(
        ids: [string, string, string?],
        startYear: number,
        endYear: number,
        pageSize: number,
        pageIndex: number,
        searchQuery: string,
        studyTypes: string[],
        journals: string[],
        origins: string[],
        relationshipTypes: string[],
        sortingOptions: SortingOptions,
        areResultsFullTextOnly: boolean = false,
        areResultsCoOccurrencesOnly: boolean = false,
        areResultsHighlighted: boolean = true,
        bookmarkIds?: BookmarkIds,
    ): void {
        this.store.dispatch(
            getArticlesRequest({
                ids,
                startYear,
                endYear,
                pageSize,
                pageIndex,
                searchQuery,
                studyTypes,
                journals,
                origins,
                relationshipTypes,
                sortingOptions,
                areResultsFullTextOnly,
                areResultsCoOccurrencesOnly,
                areResultsHighlighted,
                bookmarkIds,
            }),
        );
    }

    clearArticlesInfo(): void {
        this.store.dispatch(clearArticlesInfo());
    }

    downloadArticles(
        ids: [string, string, string?],
        startYear: number,
        endYear: number,
        pageSize: number,
        pageIndex: number,
        studyTypes: string[],
        journals: string[],
        origins: string[],
        relationshipTypes: string[],
        searchQuery: string,
        areResultsFullTextOnly: boolean = false,
        areResultsCoOccurrencesOnly: boolean = false,
        sortingOptions: SortingOptions = {
            field: SortingField.relevance,
            order: SortingOrder.descending,
        },
        bookmarkIds?: BookmarkIds,
    ): void {
        this.store.dispatch(
            downloadArticlesRequest({
                ids,
                startYear,
                endYear,
                pageSize,
                pageIndex,
                studyTypes,
                journals,
                origins,
                relationshipTypes,
                searchQuery,
                sortingOptions,
                areResultsFullTextOnly,
                areResultsCoOccurrencesOnly,
                bookmarkIds,
            }),
        );
    }

    getTermArticles(
        id: string,
        startYear: number,
        endYear: number,
        pageSize: number,
        pageIndex: number,
        searchQuery: string,
        studyTypes: string[],
        journals: string[],
        origins: string[],
        relationshipTypes: string[],
        sortingOptions: SortingOptions = {
            field: SortingField.relevance,
            order: SortingOrder.descending,
        },
        areResultsFullTextOnly: boolean = false,
        areResultsHighlighted: boolean = false,
        bookmarkIds?: BookmarkIds,
    ): void {
        const areResultsCoOccurrencesOnly: boolean = false;

        this.store.dispatch(
            getTermArticlesRequest({
                id,
                pageSize,
                pageIndex,
                sortingOptions,
                startYear,
                endYear,
                searchQuery,
                studyTypes,
                journals,
                origins,
                relationshipTypes,
                areResultsFullTextOnly,
                areResultsCoOccurrencesOnly,
                areResultsHighlighted,
                bookmarkIds,
            }),
        );
    }

    downloadTermArticles(
        id: string,
        startYear: number,
        endYear: number,
        pageSize: number,
        pageIndex: number,
        studyTypes: string[],
        journals: string[],
        origins: string[],
        relationshipTypes: string[],
        searchQuery: string,
        sortingOptions: SortingOptions = {
            field: SortingField.relevance,
            order: SortingOrder.descending,
        },
        areResultsFullTextOnly: boolean = false,
        bookmarkIds?: BookmarkIds,
    ): void {
        const areResultsCoOccurrencesOnly: boolean = false;

        this.store.dispatch(
            downloadTermArticlesRequest({
                id,
                pageSize,
                pageIndex,
                sortingOptions,
                startYear,
                endYear,
                studyTypes,
                journals,
                origins,
                relationshipTypes,
                searchQuery,
                areResultsFullTextOnly,
                areResultsCoOccurrencesOnly,
                bookmarkIds,
            }),
        );
    }

    /**
     * @param selectedTerm If `undefined`, the "Selected Term: X" field will not be rendered in the PDF.
     * @param sourceName If this and `targetName` are `undefined`, the "Discovery Term: X" field will not be rendered in the PDF.
     * @param targetName If this and `sourceName` are `undefined`, the "Discovery Term: X" field will not be rendered in the PDF.
     */
    downloadFullArticle(
        id: string,
        selectedTerm?: string,
        sourceName?: string,
        targetName?: string,
    ): void {
        this.store.dispatch(
            downloadFullArticleRequest({ id, selectedTerm, sourceName, targetName }),
        );
    }

    clearTermArticles(): void {
        this.store.dispatch(clearTermArticles());
    }

    clearCounts(): void {
        this.store.dispatch(clearCounts());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
