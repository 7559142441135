import { createSelector } from '@ngrx/store';
import { DataState } from '../../data-state.interface';
import { getDataState } from '../../index';
import * as fromArticles from './articles.reducer';
import { ArticlesState } from './articles-state.interface';

export const getArticlesState = createSelector(
    getDataState,
    (state: DataState) => state.discovery.articles,
);

export const getArticlesInfo = createSelector(getArticlesState, fromArticles.getArticlesInfo);
export const getCountsPerStudyType = createSelector(
    getArticlesState,
    fromArticles.getCountsPerStudyType,
);
export const getCountsPerJournal = createSelector(
    getArticlesState,
    fromArticles.getCountsPerJournal,
);
export const getCountsPerOrigin = createSelector(getArticlesState, fromArticles.getCountsPerOrigin);
export const getCountsPerRelationshipType = createSelector(
    getArticlesState,
    fromArticles.getCountsPerRelationshipType,
);
export const getFilteredTotal = createSelector(getArticlesState, fromArticles.getFilteredTotal);
export const getSelectedArticleInfo = createSelector(
    getArticlesState,
    fromArticles.getSelectedArticleInfo,
);
export const getArticles = createSelector(getArticlesState, fromArticles.getArticlesEntities);
export const getActiveArticles = createSelector(
    getArticlesState,
    (state: ArticlesState, props: { dateRange?: [number, number] }) =>
        fromArticles.getActiveArticles(state, props.dateRange),
);
export const getTermArticles = createSelector(getArticlesState, fromArticles.getTermArticles);
export const getTermArticlesLoading = createSelector(
    getArticlesState,
    fromArticles.getTermArticlesLoading,
);
export const getTermArticlesLoaded = createSelector(
    getArticlesState,
    fromArticles.getTermArticlesLoaded,
);
export const getTermArticlesFilteredTotal = createSelector(
    getArticlesState,
    fromArticles.getTermArticlesFilteredTotal,
);
export const getErrors = createSelector(getArticlesState, fromArticles.getErrors);
export const getLoading = createSelector(getArticlesState, fromArticles.getLoading);
export const getLoaded = createSelector(getArticlesState, fromArticles.getLoaded);
export const getBlob = createSelector(getArticlesState, fromArticles.getBlob);
export const getTermArticlesBlob = createSelector(
    getArticlesState,
    fromArticles.getTermArticlesBlob,
);
export const getFullArticleBlob = createSelector(getArticlesState, fromArticles.getFullArticleBlob);
export const getCountsPerPublicationDate = createSelector(
    getArticlesState,
    fromArticles.getCountsPerPublicationDate,
);
