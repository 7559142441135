import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { environment, appSwitcherItems } from '@environments/leap/environment';
import { filter, map, pairwise, startWith } from 'rxjs/operators';
import { isTruthy } from '@leap-common/utilities/helpers';

/** Constants */
import { CURRENT_YEAR } from '@leap-common/constants/common';
import { DMI_DEPARTMENT_NAME } from '../../../../../shared/constants/departments';

/** Services - Facades */
import { RoleService } from '../../../../auth/core/services/role.service';
import { FeatureFlagsService } from '@leap-libs/feature-flags/src/public-api';
import { CurrentUserService } from '@leap-store/core/src/lib/data/auth/services/current-user.service';
import { TokenService } from '@leap-store/core/src/lib/data/auth/services/token.service';
import { AlertsFacade } from '@leap-store/core/src/lib/ui/alerts/alerts.facade';
import { AuthFacade } from '@leap-store/core/src/lib/data/auth/auth.facade';
import { GPTFacade } from '@leap-store/core/src/lib/data/gpt/gpt.facade';
import { NotebookServersFacade } from '@leap-store/core/src/lib/data/notebook-servers/notebook-servers.facade';

/** Interfaces - Types - Enums */
import AppSwitcherItem from '@apps/leap/src/app/shared/interfaces/app-switcher-item.interface';
import MenuItem from '@leap-libs/main-menu/src/lib/menu-item.interface';
import HelperMenuItem from '@leap-libs/helper-menu/src/lib/helper-menu-item.interface';
import MenuOrientation from '@leap-libs/main-menu/src/lib/menu-orientation.type';
import HelperMenuConfig from '@leap-libs/helper-menu/src/lib/helper-menu-config.interface';
import CurrentUser from '@leap-store/core/src/lib/data/auth/interfaces/current-user.interface';
import UserApp from '@leap-store/core/src/lib/data/auth/interfaces/user-app.interface';
import UserGroup from '@leap-store/core/src/lib/data/auth/interfaces/user-group.interface';
import TokenTypes from '@leap-store/core/src/lib/data/auth/enums/token-types.enum';

@Component({
    selector: 'app-layout',
    templateUrl: 'layout.container.component.html',
})
export class LayoutContainerComponent implements OnInit, OnDestroy {
    app: string = environment.app;
    product: string = environment.product;
    enabledAppsToSwitch: AppSwitcherItem[];

    // current user
    currentUser$: Observable<CurrentUser> = this.authFacade.currentUser$;

    // menu
    menuOrientation: MenuOrientation = 'horizontal';
    mainMenuItems: MenuItem[];
    userMenuItems: MenuItem[];

    // footer
    readonly currentYear: number = CURRENT_YEAR;

    // feature flags
    isDiscoveryEnabled: boolean;
    areProjectsEnabled: boolean;
    areNotebooksEnabled: boolean;
    isConversationalSearchEnabled: boolean;
    isCowMilkProfilerEnabled: boolean;
    isDairyProfilerEnabled: boolean;
    isIngredientProfilerEnabled: boolean;
    isAppSwitcherEnabled: boolean;
    isSupportEnabled: boolean;
    isSuggestEnabled: boolean;
    isChatAssistantEnabled: boolean;

    // subscriptions
    routerSubscription: Subscription;
    roleSubscription: Subscription;
    currentUserSubscription: Subscription;

    constructor(
        private router: Router,
        private roleService: RoleService,
        private featureFlagsService: FeatureFlagsService,
        private currentUserService: CurrentUserService,
        private tokenService: TokenService,
        private alertsFacade: AlertsFacade,
        private authFacade: AuthFacade,
        private gptFacade: GPTFacade,
        private notebookServersFacade: NotebookServersFacade,
    ) {
        this.subscribeToRouterEvents();
    }

    ngOnInit(): void {
        this.rehydrateStore();
    }

    ngOnDestroy(): void {
        this.routerSubscription?.unsubscribe();
        this.roleSubscription?.unsubscribe();
        this.currentUserSubscription?.unsubscribe();
    }

    /**
     * Calls once the authFacade.rehydrateToken() with parameter TokenTypes.access and
     * TokenTypes.refresh and the authFacade.getCurrentUser().
     */
    rehydrateStore(): void {
        this.authFacade.rehydrateToken(TokenTypes.access);
        this.authFacade.rehydrateToken(TokenTypes.refresh);
        this.authFacade.getCurrentUser();
    }

    subscribeToRouterEvents(): void {
        this.routerSubscription = this.router.events
            .pipe(
                filter(
                    (event: RouterEvent) =>
                        event instanceof NavigationEnd &&
                        event.urlAfterRedirects !== '/empty-route',
                ),
                map((event: NavigationEnd) => this.getActiveRoute(event?.urlAfterRedirects)),
                startWith(undefined),
                pairwise(),
            )
            .subscribe(([previousActiveRoute, activeRoute]: [string, string]) => {
                this.initializeEnabledAppsToSwitch();
                this.initializeEnabledFeatures();

                this.updateMenuItems(activeRoute);

                // if the active route has changed, clear the active alerts
                if (previousActiveRoute !== activeRoute) {
                    this.alertsFacade.clearAlertEvents();
                }
            });
    }

    getActiveRoute(url: string): string {
        return url?.split('/')[1];
    }

    initializeEnabledAppsToSwitch(): void {
        this.enabledAppsToSwitch = appSwitcherItems.filter(
            (item: AppSwitcherItem) => !item.isDisabled,
        );
    }

    initializeEnabledFeatures(): void {
        this.isDiscoveryEnabled = this.featureFlagsService.isFeatureEnabled('discovery');
        this.areProjectsEnabled = this.featureFlagsService.isFeatureEnabled('projects');
        this.areNotebooksEnabled = this.featureFlagsService.isFeatureEnabled('notebooks');
        this.isConversationalSearchEnabled =
            this.featureFlagsService.isFeatureEnabled('conversationalSearch');
        this.isCowMilkProfilerEnabled =
            this.featureFlagsService.isFeatureEnabled('cowMilkProfiler');
        this.isDairyProfilerEnabled = this.featureFlagsService.isFeatureEnabled('dairyProfiler');
        this.isIngredientProfilerEnabled =
            this.featureFlagsService.isFeatureEnabled('ingredientProfiler');
        this.isAppSwitcherEnabled = this.featureFlagsService.isFeatureEnabled('appSwitcher');
        this.isSupportEnabled = this.featureFlagsService.isFeatureEnabled('support');
        this.isSuggestEnabled = this.featureFlagsService.isFeatureEnabled('suggest');
        this.isChatAssistantEnabled =
            this.featureFlagsService.isFeatureEnabled('chatAssistant') &&
            (this.app !== 'd3' ||
                this.currentUserService
                    .getUserIdentity()
                    ?.departments.some(({ name }: UserGroup) => name === DMI_DEPARTMENT_NAME));
    }

    updateMenuItems(activeRoute: string): void {
        if (activeRoute === 'help-center') {
            this.mainMenuItems = [];
            this.userMenuItems = [];
        } else {
            this.setupMainMenu();
            this.setupUserMenu();
        }
    }

    setupMainMenu(): void {
        const landingMenuItem: MenuItem = {
            id: 'menu-landing',
            icon: 'fa-kit fa-home-inactive',
            activeIcon: 'fa-kit fa-home-active',
            path:
                this.app === 'ingpro'
                    ? 'ingredient-profiler'
                    : this.isConversationalSearchEnabled
                    ? 'search'
                    : 'discovery',
            title: this.isConversationalSearchEnabled ? 'Search' : 'Discover',
            isPathExactMatch: true,
        };

        const discoveryMenuItem: MenuItem = {
            id: 'menu-discovery',
            icon: 'fa-regular fa-globe',
            activeIcon: 'fa-solid fa-globe',
            path: this.isConversationalSearchEnabled ? 'discovery' : 'discovery/my-discovery',
            title: this.isConversationalSearchEnabled ? 'Discover' : 'My Discovery',
            isUnclickable: !this.isConversationalSearchEnabled,
        };

        const appSwitcherMenuItem: MenuItem = {
            id: 'menu-app-switcher',
            icon: 'fa-regular fa-grid',
            path: '',
            title: 'Switch',
            helperMenuConfig: this.setupAppSwitcherHelperMenu('auth/login'),
        };

        const projectsMenuItem: MenuItem = {
            id: 'menu-projects',
            icon: 'fa-regular fa-file-chart-column',
            activeIcon: 'fa-solid fa-file-chart-column',
            path: 'projects',
            title: 'Projects',
            shouldOpenInNewTab: this.getActiveRoute(this.router.url) !== 'projects',
        };

        const conversationalSearchMenuItem: MenuItem = {
            id: 'menu-conversational-search',
            icon: 'fa-regular fa-comment',
            activeIcon: 'fa-solid fa-comment',
            path: 'search/my-searches',
            title: 'My Searches',
        };

        const cowMilkProfilerMenuItem: MenuItem = {
            id: 'menu-cow-milk-profiler',
            icon: 'fa-regular fa-microscope',
            activeIcon: 'fa-solid fa-microscope',
            path: 'cow-milk-profiler',
            title: 'Cow Milk Profiler',
        };

        const dairyProfilerMenuItem: MenuItem = {
            id: 'menu-dairy-profiler',
            icon: 'fa-kit fa-dairy-profiler-inactive',
            activeIcon: 'fa-kit fa-dairy-profiler-active',
            path: 'dairy-profiler',
            title: 'Dairy Profiler',
        };

        const ingredientProfilerMenuItem: MenuItem = {
            id: 'menu-ingredient-profiler',
            icon: 'fa-regular fa-table-cells',
            activeIcon: 'fa-solid fa-table-cells',
            path: 'ingredient-profiler/discover',
            title: 'My Discovery',
            isUnclickable: true,
        };

        const notebooksMenuItem: MenuItem = {
            id: 'menu-notebooks',
            icon: 'fa-regular fa-notebook',
            activeIcon: 'fa-solid fa-notebook',
            path: 'power-notebooks',
            title: 'Power Notebooks',
            helperMenuConfig: this.setupNotebooksHelperMenu('power-notebooks'),
        };

        this.currentUserSubscription = this.currentUser$
            .pipe(filter((currentUser: CurrentUser) => Boolean(currentUser)))
            .subscribe((currentUser: CurrentUser) => {
                const currentUserApps: string[] = currentUser.apps.map((app: UserApp) =>
                    app.name.toLowerCase(),
                );

                this.mainMenuItems = currentUserApps?.includes(this.enabledAppsToSwitch?.[0]?.id)
                    ? Array.from(
                          new Set([
                              this.isAppSwitcherEnabled && appSwitcherMenuItem,
                              landingMenuItem,
                              this.isConversationalSearchEnabled && conversationalSearchMenuItem,
                              this.isDiscoveryEnabled && discoveryMenuItem,
                              this.areProjectsEnabled && projectsMenuItem,
                              this.isCowMilkProfilerEnabled && cowMilkProfilerMenuItem,
                              this.isDairyProfilerEnabled && dairyProfilerMenuItem,
                              this.isIngredientProfilerEnabled && ingredientProfilerMenuItem,
                              this.areNotebooksEnabled && notebooksMenuItem,
                          ]),
                      ).filter(isTruthy)
                    : Array.from(
                          new Set([
                              landingMenuItem,
                              this.isConversationalSearchEnabled && conversationalSearchMenuItem,
                              this.isDiscoveryEnabled && discoveryMenuItem,
                              this.areProjectsEnabled && projectsMenuItem,
                              this.isCowMilkProfilerEnabled && cowMilkProfilerMenuItem,
                              this.isDairyProfilerEnabled && dairyProfilerMenuItem,
                              this.isIngredientProfilerEnabled && ingredientProfilerMenuItem,
                              this.areNotebooksEnabled && notebooksMenuItem,
                          ]),
                      ).filter(isTruthy);
            });
    }

    setupUserMenu(): void {
        const helpMenuItem: MenuItem = {
            id: 'help-center',
            icon: 'fa-regular fa-circle-question',
            activeIcon: 'fa-solid fa-circle-question',
            path: 'help-center',
            title: 'Help Center',
            helperMenuConfig: this.setupHelpCenterHelperMenu('help-center'),
        };

        const legalMenuItem: MenuItem = {
            id: 'about-app',
            icon: `fa-kit fa-${this.app}-icon-1`,
            path: 'about',
            title: `About ${this.product}`,
            helperMenuConfig: this.setupLegalHelperMenu('help-center'),
        };

        const userSettingsMenuItem: MenuItem = {
            id: 'menu-user-settings',
            icon: 'fa-regular fa-user',
            activeIcon: 'fa-solid fa-user',
            path: 'user-settings',
            title: 'User Profile',
        };

        const userManagementMenuItem: MenuItem = {
            id: 'menu-user-management',
            icon: 'fa-regular fa-gear-complex',
            activeIcon: 'fa-solid fa-gear-complex',
            path: 'user-management',
            title: 'User Management',
        };

        const logoutMenuItem: MenuItem = {
            id: 'menu-logout',
            icon: 'fa-regular fa-power-off',
            path: 'logout',
            title: 'Logout',
        };

        this.roleSubscription = this.roleService
            .hasUserAdministratorPrivileges()
            .subscribe((hasUserAdminPrivileges: boolean) => {
                this.userMenuItems = hasUserAdminPrivileges
                    ? Array.from(
                          new Set([
                              helpMenuItem,
                              userSettingsMenuItem,
                              userManagementMenuItem,
                              legalMenuItem,
                              logoutMenuItem,
                          ]),
                      )
                    : Array.from(
                          new Set([
                              helpMenuItem,
                              userSettingsMenuItem,
                              legalMenuItem,
                              logoutMenuItem,
                          ]),
                      );
            });
    }

    setupAppSwitcherHelperMenu(path: string): HelperMenuConfig {
        const accessToken: string = this.tokenService.getToken(TokenTypes.access);
        const refreshToken: string = this.tokenService.getToken(TokenTypes.refresh);

        return {
            mainSection: {
                title: 'Switch to',
                items: appSwitcherItems.map((item: AppSwitcherItem) => ({
                    id: `menu-app-switch-${item.id}`,
                    title: item.title,
                    path: `${item.url}/${path}?access=${accessToken}&refresh=${refreshToken}`,
                    icon: `fa-kit fa-${item.id}-icon-1`,
                    shouldOpenInNewTab: true,
                    isLinkExternal: true,
                    isDisabled: item.isDisabled,
                })),
            },
        };
    }

    setupNotebooksHelperMenu(path: string): HelperMenuConfig {
        return {
            mainSection: {
                items: [
                    {
                        id: 'menu-notebooks-my-power-notebooks',
                        title: 'My power notebooks',
                        path: `${path}/my-power-notebooks`,
                        icon: 'fa-regular fa-notebook',
                    },
                    {
                        id: 'menu-notebooks-templates',
                        title: 'Templates',
                        path: `${path}/template-notebooks`,
                        icon: 'fa-regular fa-square-list',
                    },
                    {
                        id: 'menu-notebooks-file-uploader',
                        title: 'File uploader',
                        path: `${path}/file-upload`,
                        icon: 'fa-regular fa-cloud-arrow-up',
                    },
                    {
                        id: 'menu-notebooks-file-manager',
                        title: 'File manager',
                        path: `${path}/file-manage`,
                        icon: 'fa-regular fa-folder-open',
                    },
                    {
                        id: 'menu-notebooks-pipeline-executions',
                        title: 'Pipeline Executions',
                        path: `${path}/pipeline-executions`,
                        icon: 'fa-regular fa-diagram-sankey',
                    },
                ],
            },
        };
    }

    setupHelpCenterHelperMenu(path: string): HelperMenuConfig {
        let helpMenuItems: HelperMenuItem[] = [
            {
                id: 'help-center-user-guide',
                title: 'User guide',
                path: `${path}/user-guide`,
                icon: 'fa-regular fa-circle-info',
                shouldOpenInNewTab: true,
            },
            {
                id: 'help-center-release-notes',
                title: 'Release notes',
                path: `${path}/release-notes`,
                icon: 'fa-regular fa-memo',
                shouldOpenInNewTab: true,
            },
            {
                id: 'help-center-faqs',
                path: `${path}/faqs`,
                title: 'FAQs',
                icon: 'fa-regular fa-circle-question',
                shouldOpenInNewTab: true,
            },
        ];

        if (this.isSupportEnabled) {
            const contactSupportItem: HelperMenuItem = {
                id: 'help-center-contact-support',
                path: `${path}/contact-support`,
                title: 'Contact support',
                icon: 'fa-regular fa-envelope-open-text',
                shouldOpenInNewTab: true,
            };
            helpMenuItems = [...helpMenuItems, contactSupportItem];
        }
        if (this.isSuggestEnabled) {
            const suggestIdeaItem: HelperMenuItem = {
                id: 'help-center-suggest-an-idea',
                path: `${path}/suggest-an-idea`,
                title: 'Suggest an idea',
                icon: 'fa-regular fa-comments-question',
                shouldOpenInNewTab: true,
            };
            helpMenuItems = [...helpMenuItems, suggestIdeaItem];
        }

        return {
            mainSection: {
                items: helpMenuItems,
            },
        };
    }

    setupLegalHelperMenu(path: string): HelperMenuConfig {
        return {
            mainSection: {
                items: [
                    {
                        id: 'help-center-credits',
                        path: `${path}/credits`,
                        title: 'Credits',
                        icon: `fa-kit fa-${this.app}-icon-1`,
                        shouldOpenInNewTab: true,
                    },
                    {
                        id: 'help-center-terms-of-use',
                        path: `${path}/terms-of-service`,
                        title: 'Terms of service',
                        icon: 'fa-regular fa-memo-circle-info',
                        shouldOpenInNewTab: true,
                    },
                    {
                        id: 'help-center-privacy-policy',
                        path: `${path}/privacy-policy`,
                        title: 'Privacy policy',
                        icon: 'fa-regular fa-shield-check',
                        shouldOpenInNewTab: true,
                    },
                ],
            },
            isRightAligned: true,
        };
    }

    onDiscovery(discovery: boolean): void {
        if (discovery) {
            this.router.navigateByUrl('/empty-route', { skipLocationChange: true }).then(() => {
                this.router.navigate(['discovery']);
            });
        }
    }

    /**
     * Implements user logout by calling the logoutNotebookServer(), the authFacade.logout()
     * and redirecting the user to the /login page.
     * The logout from the notebook server takes place only if the notebooks feature flag is enabled.
     */
    onLogout(logout: boolean): void {
        if (logout) {
            if (this.areNotebooksEnabled) {
                this.logoutNotebookServer();
            }

            if (this.isChatAssistantEnabled) {
                this.gptFacade.deleteAssistantQueries();
            }

            this.authFacade.logout([TokenTypes.access, TokenTypes.refresh]);
            this.router.navigate(['auth/logout']);
        }
    }

    /**
     * Removes XSRF-TOKEN cookie, closes open notebook server connections and
     * logouts from the jupyterHub.
     */
    logoutNotebookServer(): void {
        this.notebookServersFacade.deleteNotebookServer();
        document.cookie = `XSRF-TOKEN=; Path=/; Domain=.${environment.domain}; Max-Age=0`;
        this.notebookServersFacade.logoutJupyterHub();
    }
}
