// `build:production:mr` replaces `environment.ts` with `environment.production-mr.ts`.
// The list of file replacements can be found in `angular.json`.

import AppSwitcherItem from '@apps/leap/src/app/shared/interfaces/app-switcher-item.interface';
import FeatureConfig from '@leap-libs/feature-flags/src/lib/feature-config.type';

export const environment = {
    production: true,
    serverUrl: 'https://app-phi.pipacorp.com/iam/api/v1',
    notebookServerUrl: 'https://app-phi.pipacorp.com/notebooks/api/v1',
    autocompleteServerUrl: 'https://app-phi.pipacorp.com/autocomplete/api/v1',
    discoveryServerUrl: 'https://app-phi.pipacorp.com/literature-comprehension/api/v1',
    metadataServerUrl: 'https://app-phi.pipacorp.com/metadata/api/v1',
    metapathsServerUrl: 'https://app-phi.pipacorp.com/graphmeta/api/v1',
    uploadServerUrl: 'https://app-phi.pipacorp.com/datalake/api/v1',
    notificationsServerUrl: 'https://app-phi.pipacorp.com/notifications/api/v1',
    pipelineExecutionsServerUrl: 'https://app-phi.pipacorp.com/execution-engine/api/v1',
    projectsServerUrl: 'https://app-phi.pipacorp.com/projects-service/api/v2',
    projectsReportServerUrl: 'https://app-phi.pipacorp.com/projects-service-report/api/v1',
    gptServerUrl: 'https://app-phi.pipacorp.com/llm-interface/api/v1',
    reportServerUrl: 'https://app-phi.pipacorp.com/isr/api/v1',
    dairyProfilerServerUrl: 'https://app-phi.pipacorp.com/dairy-profiler/api/v1',
    profilerServerUrl: 'https://app-phi.pipacorp.com/profiler/api/v1',
    profilerAutocompleteServerUrl: 'https://app-phi.pipacorp.com/autocomplete-profiler/api/v1',
    domain: 'pipacorp.com',
    jupyterHubDomain: 'https://jupyterhub-phi.pipacorp.com',
    organization: 'mr',
    product: 'PHI',
    app: 'phi',
    gaKey: 'G-L1QRHMJ0X8',
    gtKey: 'GTM-5GS79FW',
};

export const featureFlags: FeatureConfig = {
    appSwitcher: true,
    discovery: true,
    notebooks: true,
    projects: true,
    conversationalSearch: false,
    cowMilkProfiler: false,
    dairyProfiler: false,
    ingredientProfiler: false,
    support: true,
    suggest: true,
    feedback: true,
    plantVersion: false,
    ontologies: false,
    explorationPaths: true,
    cowMilkConcentration: false,
    gpt: true,
    chatAssistant: false,
    externalPrivacyLinks: true,
    departments: false,
    relationshipOriginFilter: true,
    associationScore: true,
    novelAssociations: true,
    discoveryPreloaderText: true,
    alternativeDiscoveryColumnsOrder: false,
    tagGroups: true,
    healthLabels: true,
    moleculesWeight: false,
    molecules: false,
    labs: false,
    studyTypes: true,
    journals: true,
    dmdDatabase: false,
    emptyDatabases: true,
    proteinOrigins: false,
    literatureFilterSelector: true,
    moleculeTypeFilterSelector: false,
    filterItemTooltips: false,
    articlesReport: false,
};

export const appSwitcherItems: AppSwitcherItem[] = [
    {
        id: 'phi',
        title: 'PHI',
        url: 'https://app-phi.pipacorp.com',
        isDisabled: true,
    },
    {
        id: 'phiplant',
        title: 'PHI Plant version',
        url: 'https://app-phi-plants.pipacorp.com',
        isDisabled: false,
    },
];
