import { Component, HostBinding } from '@angular/core';

/** Constants */
import {
    TERMS_OF_USE_HEADER,
    TERMS_OF_USE_PATH,
    TERMS_OF_USE_LOADING_TEXT,
} from '../../../constants/legal';

/** Facades */
import { LayoutFacade } from '@leap-store/core/src/lib/ui/layout/layout.facade';

/** Interfaces */
import Header from '@leap-common/interfaces/header.interface';

@Component({
    selector: 'app-terms-of-use',
    templateUrl: 'terms-of-use.container.component.html',
    styleUrls: ['terms-of-use.container.component.scss'],
})
export class TermsOfUseContainerComponent {
    @HostBinding('class') classes = 'content-wrapper';

    readonly header: Header = TERMS_OF_USE_HEADER;
    readonly path: string = TERMS_OF_USE_PATH;
    readonly loadingText: string = TERMS_OF_USE_LOADING_TEXT;

    constructor(private uiFacade: LayoutFacade) {}

    onIframeScrolledToBottom(isScrolledToBottom: boolean): void {
        this.uiFacade.updateTermsOfUseAcceptanceStatus(isScrolledToBottom);
    }
}
