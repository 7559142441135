import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

/** Services */
import { FormValidationService } from './form-validation.service';
import { ArrayHandlerService } from '@leap-common/services/array-handler.service';

/** Interfaces - Types */
import FormModel from './form-model.interface';
import FormField from './form-field.interface';
import PatternValidator from './pattern-validator.interface';
import FormOptions from './form-options.interface';
import FormOrientation from './orientation.type';

@Component({
    selector: 'lib-form',
    templateUrl: 'form.component.html',
    styleUrls: ['form.component.scss'],
})
export class FormComponent implements OnInit, OnDestroy {
    @Input() options: FormOptions;
    @Input() loading: boolean;
    @Input() patchValue: Observable<any>;
    @Output() formBuilt: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
    @Output() formSubmit: EventEmitter<null> = new EventEmitter<null>();

    form: FormGroup;
    model: FormModel;
    fieldRows: FormField[][];
    orientation: FormOrientation;
    resetOnSubmit: boolean;
    patchValueSubscription: Subscription;

    constructor(
        private formBuilder: FormBuilder,
        private formValidationService: FormValidationService,
        private arrayHandlerService: ArrayHandlerService,
    ) {}

    ngOnInit(): void {
        this.handleOptions();
        this.handlePatchValue();
        this.initializeForm()
            .then(() => {
                if (this.model.validators) {
                    this.addFormGroupValidators();
                    this.onFormBuilt();
                }
            })
            .catch((error: any) => {
                console.error('Error on form initialization: ', error);
            });
    }

    ngOnDestroy(): void {
        if (this.patchValueSubscription) {
            this.patchValueSubscription.unsubscribe();
        }
    }

    /** Initializes the model and sets the field rows, the orientation and the resetOnSubmit */
    handleOptions(): void {
        // initialize form model
        this.model = {
            validators: this.options.validators,
        };
        Object.entries(this.options.fields).forEach(([key, field]: [string, FormField]) => {
            this.model[key] = field.options.defaultValue || null;
        });

        // initialize form field rows
        this.fieldRows = this.arrayHandlerService.splitInChunks(
            Object.values(this.options.fields),
            this.options.chunks ? this.options.chunks.size : 1,
            this.options.chunks && !Array.isArray(this.options.chunks.size)
                ? this.options.chunks.leadWithRemainder
                : false,
        );

        // initialize orientation
        this.orientation = this.options.orientation || 'vertical';

        // initialize reset on submit
        this.resetOnSubmit =
            this.options.resetOnSubmit === undefined ? true : this.options.resetOnSubmit;
    }

    /** Initializes the form group */
    initializeForm(): Promise<void> {
        return new Promise((resolve) => {
            this.form = this.formBuilder.group(this.createFormControls(), null);
            this.onFormBuilt();
            resolve();
        });
    }

    onFormBuilt(): void {
        this.formBuilt.emit(this.form);
    }

    /**
     * Creates and returns the collection form controls,
     * which contains the value for each field/control along with its FieldValidators,
     * in order to be passed as parameter on formGroup constructor
     */
    createFormControls(): FormModel {
        const formControls: FormModel = {};

        const formFields: FormModel = Object.keys(this.model)
            .filter((key: string) => key !== 'validators')
            .reduce((model: FormModel, key: string) => {
                model[key] = this.model[key];
                return model;
            }, {});

        Object.entries(formFields).forEach(([key, value]) => {
            const control: FormField = this.options.fields[key];
            formControls[key] = [
                value,
                this.createFormControlValidators(control),
                this.createFormControlAsyncValidators(control),
            ];
        });

        return formControls;
    }

    /**
     * Creates and returns the FormControl ValidatorFn[]
     * based on each of the given field validators
     */
    createFormControlValidators(field: FormField): ValidatorFn[] {
        const controlValidators: ValidatorFn[] = [];

        if (field.validators) {
            if (field.validators.required) {
                controlValidators.push(
                    this.formValidationService.requiredValidator(field.validators.required.error),
                );
            }

            if (field.validators.whitespace) {
                controlValidators.push(
                    this.formValidationService.whitespaceValidator(
                        field.validators.whitespace.error,
                    ),
                );
            }

            if (field.validators.minLength) {
                controlValidators.push(
                    this.formValidationService.minLengthValidator(
                        field.validators.minLength.min,
                        field.validators.minLength.error,
                    ),
                );
            }

            if (field.validators.maxLength) {
                controlValidators.push(
                    this.formValidationService.maxLengthValidator(
                        field.validators.maxLength.max,
                        field.validators.maxLength.error,
                    ),
                );
            }

            if (field.validators.valueSelected) {
                controlValidators.push(
                    this.formValidationService.valueSelectedValidator(
                        field.validators.valueSelected.selected,
                        field.validators.valueSelected.error,
                    ),
                );
            }

            if (field.validators.patterns && field.validators.patterns.length) {
                field.validators.patterns.forEach((pattern: PatternValidator) => {
                    controlValidators.push(
                        this.formValidationService.patternValidator(pattern.regex, pattern.error),
                    );
                });
            }
        }

        return controlValidators;
    }

    /**
     * Creates and returns the FormControl AsyncValidatorFn[]
     * based on each of the given async field validators
     */
    createFormControlAsyncValidators(field: FormField): AsyncValidatorFn[] {
        const controlAsyncValidators: AsyncValidatorFn[] = [];

        if (field.validators && field.validators.async) {
            controlAsyncValidators.push(
                this.formValidationService.asyncValidator(
                    field.validators.async.observable,
                    field.validators.async.error,
                ),
            );
        }

        return controlAsyncValidators;
    }

    /**
     * Creates and returns a ValidatorFn[] based on the given FormModel validators
     */
    createFormGroupValidators(): ValidatorFn[] {
        const formValidators: ValidatorFn[] = [];

        if (this.model.validators.fieldsMatchValidator) {
            formValidators.push(
                this.formValidationService.fieldsMatchValidator(
                    this.model.validators.fieldsMatchValidator.controlName,
                    this.model.validators.fieldsMatchValidator.matchingControlName,
                    this.model.validators.fieldsMatchValidator.error,
                ),
            );
        }

        return formValidators;
    }

    /**
     * Calls the createFormGroupValidators() and
     * adds the formValidators it returns to the form
     */
    addFormGroupValidators(): void {
        this.form.setValidators(this.createFormGroupValidators());
    }

    /**
     * Subscribes to patchValue to update the form value when notified by the parent component
     */
    handlePatchValue(): void {
        if (this.patchValue) {
            this.patchValueSubscription = this.patchValue.subscribe((value: any) => {
                this.form.patchValue(value);
            });
        }
    }

    /**
     * Emits the form value to the parent component and resets it when resetOnSubmit is true
     */
    onSubmit(): void {
        this.formSubmit.emit({ ...this.form.value });

        if (this.resetOnSubmit) {
            this.form.reset();
        }
    }
}
