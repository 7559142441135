/** third-party libraries */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { CompoundReportParser } from '../parsers/compound-report.parser';
import ResultsRestApi from '@leap-common/rest-api-interfaces/results.rest.interface';
import Overview from '../interfaces/overview.interface';
import OverviewRestApi from '../rest-api-interfaces/overview.rest.interface';
import RelationshipGroup from '../../ingredient-profiler/enums/relationship-group.enum';
import RelationshipGroups from '../interfaces/relationship-groups.interface';
import RelationshipGroupsRestApi from '../rest-api-interfaces/relationship-groups.rest.interface';
import TargetSummaryRestApi from '../rest-api-interfaces/target-summary.rest.interface';
import RelationshipGroupSummaries from '../interfaces/relationship-group-summaries.interface';
import HealthLabel from '../interfaces/health-label.interface';
import HealthLabelRestApi from '../rest-api-interfaces/health-label.rest.interface';
import StudyTypes from '../interfaces/study-types.interface';
import StudyTypesRestApi from '../rest-api-interfaces/study-types.rest.interface';
import StudyType from '../interfaces/study-type.interface';
import StudyTypeRestApi from '../rest-api-interfaces/study-type.rest.interface';
import Summary from '../../report/interfaces/summary.interface';
import SummaryRestApi from '../rest-api-interfaces/summary.rest.interface';

@Injectable()
export class CompoundReportService {
    constructor(private http: HttpClient, private compoundReportParser: CompoundReportParser) {}

    getOverview(id: string): Observable<{ overview: Overview; total: number }> {
        return this.http
            .post(`${environment.profilerServerUrl}/molecule-report?section=Overview`, {
                moleculeCui: id,
            })
            .pipe(
                map((overview: OverviewRestApi) => ({
                    overview: this.compoundReportParser.parseOverview(overview),
                    total: this.compoundReportParser.parseTotal(overview),
                })),
            );
    }

    getRelationshipGroups(id: string): Observable<RelationshipGroups> {
        return this.http
            .post(
                `${environment.profilerServerUrl}/molecule-report?section=Relationship type group&subsection=overview`,
                {
                    moleculeCui: id,
                },
            )
            .pipe(
                map((relationshipGroups: RelationshipGroupsRestApi) =>
                    this.compoundReportParser.parseRelationshipGroups(relationshipGroups),
                ),
            );
    }

    getRelationshipGroupSummaries(
        id: string,
        relationshipGroup: RelationshipGroup,
    ): Observable<RelationshipGroupSummaries> {
        return this.http
            .post(
                `${environment.profilerServerUrl}/molecule-report?section=Relationship type group&subsection=summary`,
                {
                    moleculeCui: id,
                    relationshipTypeGroup: relationshipGroup,
                },
            )
            .pipe(
                map(({ summaries }: { summaries: TargetSummaryRestApi[] }) => ({
                    relationshipGroup,
                    summaries: this.compoundReportParser.parseTargetSummaries(summaries),
                })),
            );
    }

    getHealthLabels(id: string): Observable<HealthLabel[]> {
        return this.http
            .post(
                `${environment.profilerServerUrl}/molecule-report?section=Health area&subsection=overview`,
                {
                    moleculeCui: id,
                },
            )
            .pipe(
                map((healthLabels: ResultsRestApi<HealthLabelRestApi>) =>
                    this.compoundReportParser.parseHealthLabels(healthLabels?.results),
                ),
            );
    }

    getHealthLabelSummary(id: string, healthLabel: string): Observable<Summary> {
        return this.http
            .post(
                `${environment.profilerServerUrl}/molecule-report?section=Health area&subsection=summary`,
                {
                    moleculeCui: id,
                    healthArea: healthLabel,
                },
            )
            .pipe(
                map((summary: SummaryRestApi) =>
                    this.compoundReportParser.parseSummary(summary, healthLabel),
                ),
            );
    }

    getStudyTypes(id: string): Observable<StudyTypes> {
        return this.http
            .post(
                `${environment.profilerServerUrl}/molecule-report?section=Type of study&subsection=overview`,
                {
                    moleculeCui: id,
                },
            )
            .pipe(
                map((studyTypes: StudyTypesRestApi) =>
                    this.compoundReportParser.parseStudyTypes(studyTypes),
                ),
            );
    }

    getStudyType(id: string, name: string): Observable<StudyType> {
        return this.http
            .post(
                `${environment.profilerServerUrl}/molecule-report?section=Type of study&subsection=summary`,
                {
                    moleculeCui: id,
                    typeOfStudy: name,
                },
            )
            .pipe(
                map((studyType: StudyTypeRestApi) =>
                    this.compoundReportParser.parseStudyType(studyType, name),
                ),
            );
    }
}
