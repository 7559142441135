/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/**  custom imports */
import { CompoundReportState } from './compound-report-state.interface';
import {
    getOverviewRequest,
    getRelationshipGroupsRequest,
    getRelationshipGroupSummariesRequest,
    getHealthLabelsRequest,
    getHealthLabelSummaryRequest,
    getStudyTypesRequest,
    getStudyTypeRequest,
    resetReport,
    clearNextError,
} from './compound-report.actions';
import {
    getErrors,
    getOverview,
    getOverviewLoading,
    getOverviewLoaded,
    getRelationshipGroups,
    getRelationshipGroupsLoading,
    getRelationshipGroupsLoaded,
    getRelationshipGroupSummaries,
    getHealthLabels,
    getHealthLabelsLoading,
    getHealthLabelsLoaded,
    getHealthLabelSummary,
    getStudyTypes,
    getStudyTypesLoading,
    getStudyTypesLoaded,
    getStudyType,
    getTotal,
} from './compound-report.selectors';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import Overview from './interfaces/overview.interface';
import RelationshipGroups from './interfaces/relationship-groups.interface';
import RelationshipGroup from '../ingredient-profiler/enums/relationship-group.enum';
import HealthLabel from './interfaces/health-label.interface';
import StudyTypes from './interfaces/study-types.interface';
import StudyType from './interfaces/study-type.interface';
import RelationshipGroupSummaries from './interfaces/relationship-group-summaries.interface';
import Summary from '../report/interfaces/summary.interface';

@Injectable()
export class CompoundReportFacade {
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    overview$: Observable<Overview> = this.store.pipe(select(getOverview));
    overviewLoading$: Observable<boolean> = this.store.pipe(select(getOverviewLoading));
    overviewLoaded$: Observable<boolean> = this.store.pipe(select(getOverviewLoaded));
    relationshipGroups$: Observable<RelationshipGroups> = this.store.pipe(
        select(getRelationshipGroups),
    );
    relationshipGroupsLoading$: Observable<boolean> = this.store.pipe(
        select(getRelationshipGroupsLoading),
    );
    relationshipGroupsLoaded$: Observable<boolean> = this.store.pipe(
        select(getRelationshipGroupsLoaded),
    );
    relationshipGroupSummaries$: Observable<RelationshipGroupSummaries> = this.store.pipe(
        select(getRelationshipGroupSummaries),
    );
    healthLabels$: Observable<HealthLabel[]> = this.store.pipe(select(getHealthLabels));
    healthLabelsLoading$: Observable<boolean> = this.store.pipe(select(getHealthLabelsLoading));
    healthLabelsLoaded$: Observable<boolean> = this.store.pipe(select(getHealthLabelsLoaded));
    healthLabelSummary$: Observable<Summary> = this.store.pipe(select(getHealthLabelSummary));
    studyTypes$: Observable<StudyTypes> = this.store.pipe(select(getStudyTypes));
    studyTypesLoading$: Observable<boolean> = this.store.pipe(select(getStudyTypesLoading));
    studyTypesLoaded$: Observable<boolean> = this.store.pipe(select(getStudyTypesLoaded));
    studyType$: Observable<StudyType> = this.store.pipe(select(getStudyType));
    total$: Observable<number> = this.store.pipe(select(getTotal));

    constructor(private store: Store<CompoundReportState>) {}

    getOverview({ id }: { id: string }): void {
        this.store.dispatch(getOverviewRequest({ id }));
    }

    getRelationshipGroups({ id }: { id: string }): void {
        this.store.dispatch(getRelationshipGroupsRequest({ id }));
    }

    getRelationshipGroupSummaries({
        id,
        relationshipGroup,
    }: {
        id: string;
        relationshipGroup: RelationshipGroup;
    }): void {
        this.store.dispatch(getRelationshipGroupSummariesRequest({ id, relationshipGroup }));
    }

    getHealthLabels({ id }: { id: string }): void {
        this.store.dispatch(getHealthLabelsRequest({ id }));
    }

    getHealthLabelSummary({ id, healthLabel }: { id: string; healthLabel: string }): void {
        this.store.dispatch(getHealthLabelSummaryRequest({ id, healthLabel }));
    }

    getStudyTypes({ id }: { id: string }): void {
        this.store.dispatch(getStudyTypesRequest({ id }));
    }

    getStudyType({ id, name }: { id: string; name: string }): void {
        this.store.dispatch(getStudyTypeRequest({ id, name }));
    }

    resetReport(): void {
        this.store.dispatch(resetReport());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
