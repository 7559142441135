import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { range } from 'lodash';

/** Helpers */
import { isFirstOccurrence } from '@leap-common/utilities/helpers';

/** Interfaces - Enums */
import PaginatedArticlesRestApi from '../rest-api-interfaces/paginated-articles.rest.interface';
import PaginatedArticles from '../interfaces/paginated-articles.interface';
import ArticleInfoRestApi from '../rest-api-interfaces/article-info.rest.interface';
import ArticleInfo from '../interfaces/article-info.interface';
import ArticleRestApi from '../rest-api-interfaces/article.rest.interface';
import Article from './../interfaces/article.interface';
import ArticleSectionRestApi from '../rest-api-interfaces/article-section.rest.interface';
import ArticleSection from '../interfaces/article-section.interface';
import ArticleParagraph from '../interfaces/article-paragraph.interface';
import Bookmark from '@leap-store/core/src/lib/data/bookmarks/enums/bookmark.enum';
import FilterCounts from '@apps/leap/src/app/shared/modules/filters/interfaces/filter-counts.interface';
import ArticleFilterCountsRestApi from '../rest-api-interfaces/filter-counts.rest.interface';
import ArticleFilterCounts from '../interfaces/filter-counts.interface';

@Injectable()
export class ArticlesParser {
    constructor() {}

    /** Parses BE articleInfo to integrate them on the FE */
    parseArticlesInfo(articlesInfo: ArticleInfoRestApi[]): ArticleInfo[] {
        return articlesInfo.map((articleInfo: ArticleInfoRestApi) =>
            this.parseArticleInfo(articleInfo),
        );
    }

    parseArticleInfo(articleInfo: ArticleInfoRestApi): ArticleInfo {
        return {
            id: this.generateArticleInfoId(articleInfo),
            sourceId: articleInfo.sourceCui,
            targetId: articleInfo.targetCui,
            intermediateId: articleInfo.intermediateCui,
            newestPublicationDate: articleInfo.newestPublicationYear,
            oldestPublicationDate: articleInfo.oldestPublicationYear,
            fullTextTotal: articleInfo.fullTextTotal,
            coOccurrencesCount: articleInfo.coOccurrencesCount,
            coOccurrencesTotal: articleInfo.coOccurrencesTotal,
            total: articleInfo.total,
        };
    }

    parsePaginatedArticles(paginatedArticles: PaginatedArticlesRestApi): PaginatedArticles {
        return {
            results: paginatedArticles.results ? this.parseArticles(paginatedArticles.results) : [],
            pageIndex: paginatedArticles.pageIndex,
            pageSize: paginatedArticles.pageSize,
            total: paginatedArticles.total,
            countsPerStudyType: paginatedArticles.counts?.type_of_study,
            countsPerJournal: paginatedArticles.counts?.journal,
            countsPerOrigin: paginatedArticles.counts?.source,
            countsPerRelationshipType: paginatedArticles.counts?.relations,
            countsPerPublicationDate: this.parseCountsPerPublicationDate(
                paginatedArticles.counts?.publication_date,
            ),
        };
    }

    parseArticles(articles: ArticleRestApi[]): Article[] {
        return articles.map((article: ArticleRestApi) => this.parseArticle(article));
    }

    parseArticle(article: ArticleRestApi): Article {
        return article
            ? {
                  type: Bookmark.article,
                  id: this.getUuid(),
                  abstract: article.abstract,
                  externalId: article.externalId,
                  publicationDate: article.publicationDate,
                  title: article.title,
                  url: article.url,
                  origin: article.source,
                  titleHighlights: article.titleSpans || [],
                  titleEntityHighlights: article.titleInsightSpans || [],
                  titleSearchHighlights: article.titleSearchSpans || [],
                  abstractHighlights: article.abstractSpans || [],
                  abstractEntityHighlights: article.abstractInsightSpans || [],
                  abstractSearchHighlights: article.abstractSearchSpans || [],
                  studyTypes: article.typesOfStudy || [],
                  journal: article.journal,
                  authors: article.authors,
                  fullText: article.fullText ? this.parseArticleFullText(article.fullText) : [],
                  isNew: article.isNew,
                  headings: article.headings,
                  relationshipTypes: this.parseRelationshipTypes(article.relations),
              }
            : null;
    }

    parseArticleFullText(fullText: ArticleSectionRestApi[]): ArticleSection[] {
        return fullText.map((section: ArticleSectionRestApi) => this.parseArticleSection(section));
    }

    parseRelationshipTypes(relationshipTypes: Record<string, string>): string[] {
        return relationshipTypes ? Object.values(relationshipTypes).filter(isFirstOccurrence) : [];
    }

    parseArticleSection(section: ArticleSectionRestApi): ArticleSection {
        return {
            title: section.section,
            paragraphs: section.paragraphs
                ? this.parseArticleParagraphs(
                      section.paragraphs,
                      section.spans,
                      section.insightSpans,
                  )
                : [],
        };
    }

    parseArticleParagraphs(
        texts: string[],
        highlights: [number, number][][],
        entityHighlights: [number, number][][],
    ): ArticleParagraph[] {
        return texts.map((text: string, index: number) => ({
            text,
            highlights: highlights?.[index] || [],
            entityHighlights: entityHighlights?.[index] || [],
        }));
    }

    parseFilterCounts(
        counts: ArticleFilterCountsRestApi,
        fullTextTotal: number,
    ): ArticleFilterCounts {
        return {
            studyTypes: counts?.type_of_study,
            journals: counts?.journal,
            origins: counts?.source,
            relationships: counts?.relations,
            fullTextTotal,
        };
    }

    /**
     * Checks if there are years not existing in the BE response due to zero data
     * and adds a zero record for these years in order to appear correctly in the line chart
     */
    parseCountsPerPublicationDate(
        countsPerPublicationDate: Record<string, FilterCounts>,
    ): Record<string, FilterCounts> {
        if (!countsPerPublicationDate || !Object.keys(countsPerPublicationDate).length) {
            return {};
        }
        const years: number[] = Object.keys(countsPerPublicationDate).map(Number);
        // Create an array with the missing years included
        const allYears: number[] = range(Math.min(...years), Math.max(...years));

        const totalCountsPerPublicationDate: Record<string, FilterCounts> = allYears.reduce(
            (accumulator: Record<string, FilterCounts>, year: number) => {
                if (!countsPerPublicationDate[year]) {
                    accumulator[year] = { active: 0, total: 0 };
                }
                return accumulator;
            },
            { ...countsPerPublicationDate },
        );

        return totalCountsPerPublicationDate;
    }

    serializeArticle(article: Article): ArticleRestApi {
        return {
            abstract: article.abstract,
            externalId: article.externalId,
            publicationDate: article.publicationDate,
            title: article.title,
            url: article.url,
            source: article.origin,
            titleSpans: article.titleHighlights,
            titleInsightSpans: article.titleEntityHighlights,
            titleSearchSpans: article.titleSearchHighlights || [],
            abstractSpans: article.abstractHighlights,
            abstractInsightSpans: article.abstractEntityHighlights,
            abstractSearchSpans: article.abstractSearchHighlights || [],
            typesOfStudy: article.studyTypes,
            journal: article.journal,
            authors: article.authors,
            fullText: article.fullText ? this.serializeArticleFullText(article.fullText) : [],
            isNew: article.isNew,
            headings: article.headings,
            relations: this.serializeRelationshipTypes(article.relationshipTypes),
        };
    }

    serializeRelationshipTypes(relationshipTypes: string[]): Record<string, string> {
        return (
            relationshipTypes?.reduce((accumulator: Record<string, string>, value: string) => {
                accumulator[value] = value;
                return accumulator;
            }, {}) || {}
        );
    }

    serializeArticleFullText(fullText: ArticleSection[]): ArticleSectionRestApi[] {
        return fullText.map((section: ArticleSection) => this.serializeArticleSection(section));
    }

    serializeArticleSection(section: ArticleSection): ArticleSectionRestApi {
        return {
            section: section.title,
            paragraphs: section.paragraphs
                ? section.paragraphs.map(({ text }: ArticleParagraph) => text)
                : [],
            spans: section.paragraphs
                ? section.paragraphs.map(({ highlights }: ArticleParagraph) => highlights)
                : [],
            insightSpans: section.paragraphs
                ? section.paragraphs.map(
                      ({ entityHighlights }: ArticleParagraph) => entityHighlights,
                  )
                : [],
        };
    }

    getUuid(): string {
        return uuid();
    }

    generateArticleInfoId(articleInfo: ArticleInfoRestApi): string {
        return articleInfo.intermediateCui
            ? `${articleInfo.sourceCui}_${articleInfo.intermediateCui}_${articleInfo.targetCui}`
            : `${articleInfo.sourceCui}_${articleInfo.targetCui}`;
    }
}
