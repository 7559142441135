/** third-party imports */
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

/** custom imports */
import { IngredientProfilerService } from './services/ingredient-profiler.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as actions from './ingredient-profiler.actions';
import Suggestion from '../discovery/suggestions/interfaces/suggestion.interface';
import PaginatedInsights from './interfaces/paginated-insights.interface';
import CustomIngredient from './interfaces/custom-ingredient.interface';
import Overview from './interfaces/overview.interface';
import HealthLabelStatistics from './interfaces/health-label-statistics.interface';
import HealthLabelSummaries from './interfaces/health-label-summaries.interface';
import HealthLabelTopCompounds from './interfaces/health-label-top-compounds.interface';
import RelationshipGroup from './enums/relationship-group.enum';

@Injectable()
export class IngredientProfilerEffects {
    constructor(
        private actions$: Actions,
        private ingredientProfilerService: IngredientProfilerService,
    ) {}

    getSuggestions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getSuggestionsRequest),
            switchMap(({ query, limit }) =>
                this.ingredientProfilerService.getSuggestions(query, limit).pipe(
                    map((suggestions: Suggestion[]) =>
                        actions.getSuggestionsSuccess({ suggestions }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getSuggestionsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getCustomIngredient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getCustomIngredientRequest),
            mergeMap(({ file }) =>
                this.ingredientProfilerService.getCustomIngredient(file).pipe(
                    map((customIngredient: CustomIngredient) =>
                        actions.getCustomIngredientSuccess({ customIngredient }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getCustomIngredientFailure({ errorResponse })),
                    ),
                    takeUntil(this.actions$.pipe(ofType(actions.quitCustomIngredientRequest))),
                ),
            ),
        ),
    );

    getInsights$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getInsightsRequest),
            mergeMap(
                ({
                    ingredientId,
                    compoundId,
                    filters,
                    pageSize,
                    pageIndex,
                    preferences,
                    sortingOptions,
                }) =>
                    this.ingredientProfilerService
                        .getInsights(
                            ingredientId,
                            compoundId,
                            filters,
                            pageSize,
                            pageIndex,
                            preferences,
                            sortingOptions,
                        )
                        .pipe(
                            map((paginatedInsights: PaginatedInsights) =>
                                actions.getInsightsSuccess({
                                    compoundId,
                                    paginatedInsights,
                                }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getInsightsFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getTargetInsights$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getTargetInsightsRequest),
            mergeMap(({ ingredientId, targetId, filters, pageSize, pageIndex, preferences }) =>
                this.ingredientProfilerService
                    .getTargetInsights(
                        ingredientId,
                        targetId,
                        filters,
                        pageSize,
                        pageIndex,
                        preferences,
                    )
                    .pipe(
                        map((paginatedInsights: PaginatedInsights) =>
                            actions.getTargetInsightsSuccess({
                                paginatedInsights,
                            }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.getTargetInsightsFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    downloadInsights$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.downloadInsightsRequest),
            switchMap(({ ingredientId, filters, sortingOptions }) =>
                this.ingredientProfilerService
                    .downloadInsights(ingredientId, filters, sortingOptions)
                    .pipe(
                        map((blob: Blob) => actions.downloadInsightsSuccess({ blob })),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.downloadInsightsFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    getCategories$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getCategoriesRequest),
            mergeMap(() =>
                this.ingredientProfilerService.getCategories().pipe(
                    map((categories: Record<string, string[]>) =>
                        actions.getCategoriesSuccess({ categories }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getCategoriesFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getOverview$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getOverviewRequest),
            switchMap(({ ingredientId, countBy, filters, preferences }) =>
                this.ingredientProfilerService
                    .getOverview(ingredientId, countBy, filters, preferences)
                    .pipe(
                        map((overview: Overview) => actions.getOverviewSuccess({ overview })),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.getOverviewFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    getHealthLabelsStatistics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getHealthLabelsStatisticsRequest),
            switchMap(({ ingredientId, filters, preferences }) =>
                this.ingredientProfilerService
                    .getHealthLabelsStatistics(ingredientId, filters, preferences)
                    .pipe(
                        map(
                            ({
                                healthLabelsStatistics,
                                relationshipsPerGroup,
                            }: {
                                healthLabelsStatistics: HealthLabelStatistics[];
                                relationshipsPerGroup: Record<RelationshipGroup, string[]>;
                            }) =>
                                actions.getHealthLabelsStatisticsSuccess({
                                    healthLabelsStatistics,
                                    relationshipsPerGroup,
                                }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.getHealthLabelsStatisticsFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    getHealthLabelSummaries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getHealthLabelSummariesRequest),
            mergeMap(({ ingredientId, healthLabel, filters, preferences }) =>
                this.ingredientProfilerService
                    .getHealthLabelSummaries(ingredientId, healthLabel, filters, preferences)
                    .pipe(
                        map((healthLabelSummaries: HealthLabelSummaries) =>
                            actions.getHealthLabelSummariesSuccess({ healthLabelSummaries }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.getHealthLabelSummariesFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    getHealthLabelTopCompounds$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getHealthLabelTopCompoundsRequest),
            mergeMap(({ ingredientId, healthLabel, filters, preferences }) =>
                this.ingredientProfilerService
                    .getHealthLabelTopCompounds(ingredientId, healthLabel, filters, preferences)
                    .pipe(
                        map((healthLabelTopCompounds: HealthLabelTopCompounds) =>
                            actions.getHealthLabelTopCompoundsSuccess({ healthLabelTopCompounds }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.getHealthLabelTopCompoundsFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    getDiscoverySuggestions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getSearchSuggestionsRequest),
            switchMap(({ ingredientId, query, activeSearch }) =>
                this.ingredientProfilerService
                    .getSearchSuggestions(ingredientId, query, activeSearch)
                    .pipe(
                        map((suggestionIds: string[]) =>
                            actions.getSearchSuggestionsSuccess({ suggestionIds }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.getSearchSuggestionsFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );
}
