/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as actions from './articles.actions';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { ArticlesState } from './articles-state.interface';
import PaginatedArticles from './interfaces/paginated-articles.interface';
import ArticleInfo from './interfaces/article-info.interface';
import Article from './interfaces/article.interface';
import FilterCounts from '@apps/leap/src/app/shared/modules/filters/interfaces/filter-counts.interface';

export const adapter: EntityAdapter<Article> = createEntityAdapter<Article>();

export const initialState: ArticlesState = adapter.getInitialState({
    errors: [],
    loading: false,
    loaded: false,
    articlesInfo: [],
    countsPerStudyType: {},
    countsPerJournal: {},
    countsPerOrigin: {},
    countsPerRelationshipType: {},
    countsPerPublicationDate: {},
    filteredTotal: 0,
    selectedArticleInfo: null,
    termArticles: null,
    termArticlesLoading: false,
    termArticlesLoaded: false,
    termArticlesFilteredTotal: 0,
    blob: null,
    termArticlesBlob: null,
    fullArticleBlob: null,
});

const articleReducer: ActionReducer<ArticlesState, Action> = createReducer(
    initialState,
    on(actions.getArticlesInfoRequest, (state: ArticlesState) => ({
        ...state,
        loading: true,
        loaded: false,
    })),
    on(
        actions.getArticlesInfoSuccess,
        (
            state: ArticlesState,
            {
                articlesInfo,
                isSelected,
            }: {
                articlesInfo: ArticleInfo[];
                isSelected: boolean;
            },
        ) => {
            const uniqueArticlesInfo: Map<string, ArticleInfo> = new Map();

            state.articlesInfo.forEach((articleInfo: ArticleInfo) =>
                uniqueArticlesInfo.set(articleInfo.id, articleInfo),
            );

            articlesInfo.forEach((articleInfo: ArticleInfo) =>
                uniqueArticlesInfo.set(articleInfo.id, articleInfo),
            );

            return {
                ...state,
                articlesInfo: !isSelected
                    ? Array.from(uniqueArticlesInfo.values())
                    : state.articlesInfo,
                selectedArticleInfo: isSelected ? articlesInfo[0] : state.selectedArticleInfo,
            };
        },
    ),
    on(
        actions.getArticlesInfoFailure,
        (
            state: ArticlesState,
            { errorResponse, entity }: { errorResponse: HttpErrorResponse; entity: string },
        ) => ({
            ...state,
            errors: [...state.errors, { ...errorResponse.error, entity }],
        }),
    ),
    on(
        actions.selectArticleInfo,
        (
            state: ArticlesState,
            {
                sourceId,
                targetId,
                intermediateId,
            }: { sourceId: string; targetId: string; intermediateId?: string },
        ) => {
            const selectedArticleInfo: ArticleInfo = (state.articlesInfo || []).find(
                (articleInfo: ArticleInfo) =>
                    articleInfo.sourceId === sourceId &&
                    articleInfo.targetId === targetId &&
                    (!intermediateId || articleInfo.intermediateId === intermediateId), // intermediateId maybe undefined and articleInfo.intermediateId null
            );
            return {
                ...state,
                selectedArticleInfo,
            };
        },
    ),
    on(actions.clearSelectedArticleInfo, (state: ArticlesState) => ({
        ...state,
        selectedArticleInfo: null as ArticleInfo,
    })),
    on(actions.clearArticlesInfo, (state: ArticlesState) => ({
        ...state,
        articlesInfo: [] as ArticleInfo[],
    })),
    on(actions.getArticlesRequest, (state: ArticlesState) => ({
        ...state,
        loading: true,
        loaded: false,
    })),
    on(
        actions.getArticlesSuccess,
        (state: ArticlesState, { paginatedArticles }: { paginatedArticles: PaginatedArticles }) =>
            adapter.setAll(paginatedArticles.results, {
                ...state,
                loading: false,
                loaded: true,
                countsPerStudyType: paginatedArticles.countsPerStudyType,
                countsPerJournal: paginatedArticles.countsPerJournal,
                countsPerOrigin: paginatedArticles.countsPerOrigin,
                countsPerRelationshipType: paginatedArticles.countsPerRelationshipType,
                countsPerPublicationDate: paginatedArticles.countsPerPublicationDate,
                filteredTotal: paginatedArticles.total,
            }),
    ),
    on(
        actions.getArticlesFailure,
        (state: ArticlesState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            loading: false,
            loaded: false,
        }),
    ),
    on(actions.downloadArticlesRequest, (state: ArticlesState) => ({
        ...state,
        blob: null as Blob,
    })),
    on(actions.downloadArticlesSuccess, (state: ArticlesState, { blob }: { blob: Blob }) => ({
        ...state,
        blob,
    })),
    on(
        actions.downloadArticlesFailure,
        (state: ArticlesState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(actions.getTermArticlesRequest, (state: ArticlesState) => ({
        ...state,
        termArticlesLoading: true,
        termArticlesLoaded: false,
    })),
    on(
        actions.getTermArticlesSuccess,
        (
            state: ArticlesState,
            { paginatedTermArticles }: { paginatedTermArticles: PaginatedArticles },
        ) => ({
            ...state,
            termArticles: paginatedTermArticles.results,
            termArticlesLoading: false,
            termArticlesLoaded: true,
            countsPerStudyType: paginatedTermArticles.countsPerStudyType,
            countsPerJournal: paginatedTermArticles.countsPerJournal,
            countsPerOrigin: paginatedTermArticles.countsPerOrigin,
            countsPerPublicationDate: paginatedTermArticles.countsPerPublicationDate,
            termArticlesFilteredTotal: paginatedTermArticles.total,
        }),
    ),
    on(
        actions.getTermArticlesFailure,
        (state: ArticlesState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            termArticlesLoading: false,
            termArticlesLoaded: false,
        }),
    ),
    on(actions.downloadTermArticlesRequest, (state: ArticlesState) => ({
        ...state,
        termArticlesBlob: null as Blob,
    })),
    on(actions.downloadTermArticlesSuccess, (state: ArticlesState, { blob }: { blob: Blob }) => ({
        ...state,
        termArticlesBlob: blob,
    })),
    on(
        actions.downloadTermArticlesFailure,
        (state: ArticlesState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(actions.downloadFullArticleRequest, (state: ArticlesState) => ({
        ...state,
        fullArticleBlob: null as Blob,
    })),
    on(actions.downloadFullArticleSuccess, (state: ArticlesState, { blob }: { blob: Blob }) => ({
        ...state,
        fullArticleBlob: blob,
    })),
    on(
        actions.downloadFullArticleFailure,
        (state: ArticlesState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(actions.clearTermArticles, (state: ArticlesState) => ({
        ...state,
        termArticles: null as Article[],
        termArticlesLoading: false,
        termArticlesLoaded: false,
    })),
    on(actions.clearCounts, (state: ArticlesState) => ({
        ...state,
        countsPerStudyType: {},
        countsPerJournal: {},
        countsPerOrigin: {},
        countsPerRelationshipType: {},
        countsPerPublicationDate: {},
        filteredTotal: 0,
        termArticlesFilteredTotal: 0,
    })),
    on(actions.clearNextError, (state: ArticlesState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (state: ArticlesState | undefined, action: Action): ArticlesState =>
    articleReducer(state, action);

// selectors
export const getArticlesInfo: (state: ArticlesState) => ArticleInfo[] = (state: ArticlesState) =>
    state.articlesInfo;
export const getCountsPerStudyType: (state: ArticlesState) => Record<string, FilterCounts> = (
    state: ArticlesState,
) => state.countsPerStudyType;
export const getCountsPerJournal: (state: ArticlesState) => Record<string, FilterCounts> = (
    state: ArticlesState,
) => state.countsPerJournal;
export const getCountsPerOrigin: (state: ArticlesState) => Record<string, FilterCounts> = (
    state: ArticlesState,
) => state.countsPerOrigin;
export const getCountsPerRelationshipType: (
    state: ArticlesState,
) => Record<string, FilterCounts> = (state: ArticlesState) => state.countsPerRelationshipType;
export const getCountsPerPublicationDate: (state: ArticlesState) => Record<string, FilterCounts> = (
    state: ArticlesState,
) => state.countsPerPublicationDate;
export const getFilteredTotal: (state: ArticlesState) => number = (state: ArticlesState) =>
    state.filteredTotal;
export const getSelectedArticleInfo: (state: ArticlesState) => ArticleInfo = (
    state: ArticlesState,
) => state.selectedArticleInfo;
export const getArticlesEntities: (state: ArticlesState) => Article[] =
    adapter.getSelectors().selectAll;
export const getActiveArticles = (
    state: ArticlesState,
    dateRange?: [number, number],
): Article[] => {
    if (!dateRange) {
        return getArticlesEntities(state);
    }

    return getArticlesEntities(state).filter((article: Article) => {
        const date: number = Number(article.publicationDate.split('-')[0]);
        return date >= dateRange[0] && date <= dateRange[1];
    });
};
export const getTermArticles: (state: ArticlesState) => Article[] = (state: ArticlesState) =>
    state.termArticles;
export const getTermArticlesLoading: (state: ArticlesState) => boolean = (state: ArticlesState) =>
    state.termArticlesLoading;
export const getTermArticlesLoaded: (state: ArticlesState) => boolean = (state: ArticlesState) =>
    state.termArticlesLoaded;
export const getTermArticlesFilteredTotal: (state: ArticlesState) => number = (
    state: ArticlesState,
) => state.termArticlesFilteredTotal;
export const getErrors: (state: ArticlesState) => ErrorResponse[] = (state: ArticlesState) =>
    state.errors;
export const getLoading: (state: ArticlesState) => boolean = (state: ArticlesState) =>
    state.loading;
export const getLoaded: (state: ArticlesState) => boolean = (state: ArticlesState) => state.loaded;
export const getBlob: (state: ArticlesState) => Blob = (state: ArticlesState) => state.blob;
export const getTermArticlesBlob: (state: ArticlesState) => Blob = (state: ArticlesState) =>
    state.termArticlesBlob;
export const getFullArticleBlob: (state: ArticlesState) => Blob = (state: ArticlesState) =>
    state.fullArticleBlob;
