/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { PatentsState } from './patents-state.interface';
import {
    clearNextError,
    clearPatents,
    downloadPatentsRequest,
    getPatentsRequest,
} from './patents.actions';
import {
    getErrors,
    getPatents,
    getLoading,
    getLoaded,
    getPublicationDateRange,
    getFilteredTotal,
    getBlob,
} from './patents.selectors';
import Patent from './interfaces/patent.interface';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';

@Injectable()
export class PatentsFacade {
    patents$: Observable<Patent[]> = this.store.pipe(select(getPatents));
    publicationDateRange$: Observable<[number, number]> = this.store.pipe(
        select(getPublicationDateRange),
    );
    filteredTotal$: Observable<number> = this.store.pipe(select(getFilteredTotal));
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    loading$: Observable<boolean> = this.store.pipe(select(getLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getLoaded));
    blob$: Observable<Blob> = this.store.pipe(select(getBlob));

    constructor(private store: Store<PatentsState>) {}

    getPatents(
        id: string,
        sortingOptions?: SortingOptions,
        startYear?: number,
        endYear?: number,
        pageSize?: number,
        pageIndex?: number,
    ): void {
        this.store.dispatch(
            getPatentsRequest({ id, sortingOptions, startYear, endYear, pageSize, pageIndex }),
        );
    }

    downloadPatents(
        id: string,
        sortingOptions?: SortingOptions,
        startYear?: number,
        endYear?: number,
    ): void {
        this.store.dispatch(
            downloadPatentsRequest({
                id,
                sortingOptions,
                startYear,
                endYear,
            }),
        );
    }

    clearPatents(): void {
        this.store.dispatch(clearPatents());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
