import { Injectable } from '@angular/core';

/** custom imports */
import { ArrayHandlerService } from '@leap-common/services/array-handler.service';
import { InsightParser } from '@apps/leap/src/app/shared/parsers/insight.parser';
import { DiscoveryParser } from '@apps/leap/src/app/shared/parsers/discovery.parser';
import { OpenDiscoveryParser } from '../../discovery/open/parsers/open-discovery.parser';
import { ClosedDiscoveryParser } from '../../discovery/closed/parsers/closed-discovery.parser';
import { ArticlesParser } from '../../discovery/articles/parsers/articles.parser';

/** Helpers */
import { isNewerOrSameVersion, mapEmailToUsername } from '@leap-common/utilities/helpers';

/** Constants */
import {
    ARTICLES_REPORT_UKG_VERSION,
    CLOSED_DISCOVERY_SOURCE_TARGET_ARTICLES_UKG_VERSION,
    LEGACY_UKG_VERSION,
} from '../constants/bookmarks';

/** Interfaces - Types - Enums */
import PaginatedBookmarksRestApi from '../rest-api-interfaces/paginated-bookmarks.rest.interface';
import PaginatedBookmarks from '../interfaces/paginated-bookmarks.interface';
import Bookmark from '../interfaces/bookmark.interface';
import BookmarkData from '../types/bookmark-data.type';
import BookmarkType from '../enums/bookmark.enum';
import BookmarkRestApi from '../rest-api-interfaces/bookmark.rest.interface';
import BookmarkConfigurationRestApi from '../rest-api-types/configuration.rest.type';
import Entity from '../interfaces/entity.interface';
import ArticleRestApi from '../../discovery/articles/rest-api-interfaces/article.rest.interface';
import OpenDiscoveryInsightRestApi from '../../discovery/open/rest-api-interfaces/insight.rest.interface';
import ClosedDiscoveryInsightRestApi from '../../discovery/closed/rest-api-interfaces/insight.rest.interface';
import EntityRestApi from '../rest-api-interfaces/entity.rest.interface';
import Discovery from '@apps/leap/src/app/shared/enums/discovery.enum';
import BookmarkOrigin from '../interfaces/origin.interface';
import BookmarkConfiguration from '../interfaces/configuration.interface';
import BookmarkConfigurationItemRestApi from '../rest-api-interfaces/configuration-item.rest.interface';
import InsightFilterCounts from '@apps/leap/src/app/shared/modules/insight-filters/interfaces/counts.interface';
import InsightFilterCountsRestApi from '@apps/leap/src/app/shared/modules/insight-filters/rest-api-interfaces/counts.rest.interface';
import ArticleFilterCounts from '../../discovery/articles/interfaces/filter-counts.interface';
import ArticleFilterCountsRestApi from '@leap-store/core/src/lib/data/discovery/articles/rest-api-interfaces/filter-counts.rest.interface';

@Injectable()
export class BookmarksParser {
    constructor(
        private arrayHandlerService: ArrayHandlerService,
        private insightParser: InsightParser,
        private discoveryParser: DiscoveryParser,
        private openDiscoveryInsightsParser: OpenDiscoveryParser,
        private closedDiscoveryInsightsParser: ClosedDiscoveryParser,
        private articlesParser: ArticlesParser,
    ) {}

    parsePaginatedResults(
        paginatedBookmarks: PaginatedBookmarksRestApi,
        type: BookmarkType,
        studyTypesOrder: string[],
    ): PaginatedBookmarks {
        return {
            results: paginatedBookmarks.results
                ? this.parseBookmarks(paginatedBookmarks.results, type, studyTypesOrder)
                : [],
            filterCounts: this.parseFilterCounts(
                paginatedBookmarks.counts,
                type,
                paginatedBookmarks.full_text_total,
            ),
            pageIndex: paginatedBookmarks.pageIndex,
            pageSize: paginatedBookmarks.pageSize,
            total: paginatedBookmarks.total,
            preferences: paginatedBookmarks.preferences,
            dateRange: [
                paginatedBookmarks.oldest_publication_year,
                paginatedBookmarks.newest_publication_year,
            ],
            cowMilkConcentrationRange: [
                paginatedBookmarks.smallestCowMilkConcentration,
                paginatedBookmarks.largestCowMilkConcentration,
            ],
            moleculeWeightRange: [
                paginatedBookmarks.smallestMolecularWeight,
                paginatedBookmarks.largestMolecularWeight,
            ],
        };
    }

    parseBookmarks(
        bookmarks: BookmarkRestApi[],
        type: BookmarkType,
        studyTypesOrder: string[],
    ): Bookmark[] {
        return bookmarks.map((bookmark: BookmarkRestApi) =>
            this.parseBookmark(bookmark, type, studyTypesOrder),
        );
    }

    parseBookmark(
        bookmark: BookmarkRestApi,
        type: BookmarkType,
        studyTypesOrder: string[],
    ): Bookmark {
        const parsedBookmarkData: BookmarkData =
            type === BookmarkType.entity
                ? this.parseEntity(bookmark.data as EntityRestApi)
                : type === BookmarkType.associationOpen
                ? this.openDiscoveryInsightsParser.parseInsight(
                      bookmark.data as OpenDiscoveryInsightRestApi,
                  )
                : type === BookmarkType.associationClosed
                ? this.closedDiscoveryInsightsParser.parseInsight(
                      bookmark.data as ClosedDiscoveryInsightRestApi,
                      studyTypesOrder,
                  )
                : type === BookmarkType.article
                ? this.articlesParser.parseArticle(bookmark.data as ArticleRestApi)
                : undefined;
        const isLegacy: boolean = bookmark.ukg_version === LEGACY_UKG_VERSION;

        return {
            data: parsedBookmarkData,
            id: bookmark.uuid,
            createdBy: bookmark.creator_mail
                ? mapEmailToUsername(bookmark.creator_mail)
                : undefined,
            createdAt: bookmark.created_at,
            updatedAt: bookmark.updated_at,
            notes: bookmark.note,
            configuration: this.parseConfiguration(bookmark.configuration, type),
            isLegacy,
            isReportEnabled:
                type === BookmarkType.article
                    ? parsedBookmarkData &&
                      !isLegacy &&
                      isNewerOrSameVersion(bookmark.ukg_version, ARTICLES_REPORT_UKG_VERSION)
                    : undefined,
            areAssociationClosedSourceTargetArticlesEnabled: isNewerOrSameVersion(
                bookmark.ukg_version,
                CLOSED_DISCOVERY_SOURCE_TARGET_ARTICLES_UKG_VERSION,
            ),
        };
    }

    parseEntity(entity: EntityRestApi): Entity {
        return {
            type: BookmarkType.entity,
            id: entity.uid,
            name: entity.name,
            categories: this.arrayHandlerService.sortObjectByValues(entity.categories) || {},
            synonyms: entity.synonyms || [],
            definitions: entity.definitions || {},
            tags: this.insightParser.parseTags(entity.tags?.Tags),
            healthLabels: this.insightParser.parseHealthLabels(entity.tags?.['Health areas']),
            molecules: this.insightParser.parseMolecules(entity.tags?.['Molecule classification']),
            labs: this.insightParser.parseLabs(entity.tags?.['UCD DMD lab']),
            patentsCount: entity.noOfPatents,
            moleculeWeight: entity.molecularWeight,
            cowMilkConcentration: entity.cowMilkConcentration,
        };
    }

    parseConfiguration(
        configuration: BookmarkConfigurationRestApi,
        type: BookmarkType,
    ): BookmarkConfiguration {
        // parsing for the other cases can be added if needed
        if (type === BookmarkType.article) {
            const sourceItem: BookmarkConfigurationItemRestApi =
                configuration.source as BookmarkConfigurationItemRestApi;
            const intermediateItem: BookmarkConfigurationItemRestApi =
                configuration.intermediate as BookmarkConfigurationItemRestApi;
            const targetItem: BookmarkConfigurationItemRestApi =
                configuration.target as BookmarkConfigurationItemRestApi;

            return {
                type,
                id: configuration.external_id as string,
                sourceId: sourceItem?.uid,
                sourceName: sourceItem?.name,
                intermediateId: intermediateItem?.uid,
                intermediateName: intermediateItem?.name,
                targetId: targetItem?.uid,
                targetName: targetItem?.name,
            };
        }
    }

    parseFilterCounts(
        counts: InsightFilterCountsRestApi | ArticleFilterCountsRestApi,
        type: BookmarkType,
        fullTextTotal?: number,
    ): InsightFilterCounts | ArticleFilterCounts {
        return type === BookmarkType.entity ||
            type === BookmarkType.associationOpen ||
            type === BookmarkType.associationClosed
            ? this.insightParser.parseFilterCounts(counts as InsightFilterCountsRestApi)
            : type === BookmarkType.article
            ? this.articlesParser.parseFilterCounts(
                  counts as ArticleFilterCountsRestApi,
                  fullTextTotal,
              )
            : null;
    }

    serializeBookmarkType(bookmarkType: BookmarkType, isPlural?: boolean): string {
        return bookmarkType === BookmarkType.entity
            ? isPlural
                ? 'entities'
                : 'entity'
            : bookmarkType === BookmarkType.associationOpen ||
              bookmarkType === BookmarkType.associationClosed
            ? isPlural
                ? 'associations'
                : 'association'
            : bookmarkType === BookmarkType.article
            ? isPlural
                ? 'articles'
                : 'article'
            : undefined;
    }

    serializeOrigin(origin: BookmarkOrigin): BookmarkConfigurationRestApi {
        return {
            type: this.discoveryParser.serializeDiscovery(origin.type),
            source: this.serializeConfigurationItem(origin.sourceId, origin.sourceName),
            target:
                origin.type === Discovery.closed
                    ? this.serializeConfigurationItem(origin.targetId, origin.targetName)
                    : undefined,
        };
    }

    serializeConfiguration(configuration: BookmarkConfiguration): BookmarkConfigurationRestApi {
        return configuration.type === BookmarkType.entity
            ? {
                  entity: this.serializeConfigurationItem(configuration.id, configuration.name),
              }
            : configuration.type === BookmarkType.associationOpen
            ? {
                  type: this.discoveryParser.serializeDiscovery(Discovery.open),
                  source: this.serializeConfigurationItem(
                      configuration.sourceId,
                      configuration.sourceName,
                  ),
                  target: this.serializeConfigurationItem(
                      configuration.targetId,
                      configuration.targetName,
                  ),
              }
            : configuration.type === BookmarkType.associationClosed
            ? {
                  type: this.discoveryParser.serializeDiscovery(Discovery.closed),
                  source: this.serializeConfigurationItem(
                      configuration.sourceId,
                      configuration.sourceName,
                  ),
                  intermediate: this.serializeConfigurationItem(
                      configuration.intermediateId,
                      configuration.intermediateName,
                  ),
                  target: this.serializeConfigurationItem(
                      configuration.targetId,
                      configuration.targetName,
                  ),
              }
            : configuration.type === BookmarkType.article
            ? {
                  external_id: configuration.id,
                  source: this.serializeConfigurationItem(
                      configuration.sourceId,
                      configuration.sourceName,
                  ),
                  intermediate: configuration.intermediateId
                      ? this.serializeConfigurationItem(
                            configuration.intermediateId,
                            configuration.intermediateName,
                        )
                      : undefined,
                  target: this.serializeConfigurationItem(
                      configuration.targetId,
                      configuration.targetName,
                  ),
              }
            : null;
    }

    serializeConfigurationItem(id: string, name: string): BookmarkConfigurationItemRestApi {
        return {
            uid: id,
            name,
        };
    }
}
