import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { environment } from '@environments/leap/environment';

/** Constants */
import { DMI_DEPARTMENT_NAME } from '../../../../shared/constants/departments';

/** Services - Facades */
import { AuthService } from '../services/auth.service';
import { CurrentUserService } from '@leap-store/core/src/lib/data/auth/services/current-user.service';
import { RefreshTokenService } from '@leap-store/core/src/lib/data/auth/services/refresh-token.service';
import { FeatureFlagsService } from '@leap-libs/feature-flags/src/public-api';
import { AuthFacade } from '@leap-store/core/src/lib/data/auth/auth.facade';
import { GPTFacade } from '@leap-store/core/src/lib/data/gpt/gpt.facade';
import { NotebookServersFacade } from '@leap-store/core/src/lib/data/notebook-servers/notebook-servers.facade';

/** Interfaces - Enums */
import TokenTypes from '@leap-store/core/src/lib/data/auth/enums/token-types.enum';
import CurrentUser from '@leap-store/core/src/lib/data/auth/interfaces/current-user.interface';
import UserGroup from '@leap-store/core/src/lib/data/auth/interfaces/user-group.interface';

@Injectable()
export class AuthGuard implements CanActivate {
    connections$: Observable<number | boolean> = this.notebookServersFacade.connections$;

    constructor(
        private authService: AuthService,
        private currentUserService: CurrentUserService,
        private refreshTokenService: RefreshTokenService,
        private featureFlagsService: FeatureFlagsService,
        private router: Router,
        private authFacade: AuthFacade,
        private gptFacade: GPTFacade,
        private notebookServersFacade: NotebookServersFacade,
    ) {}

    /**
     * Activates the protected route based on the authService.isUserAuthenticated().
     * If the user is not authenticated, delete assistant queries, log them out, close any existing open notebook server
     * connections and re-route them to the /auth/login route.
     */
    canActivate(): Observable<boolean> {
        return this.authService.isUserAuthenticated().pipe(
            map((isUserAuthenticated: boolean) => {
                const currentUser: CurrentUser = this.currentUserService.getUserIdentity();
                const hasUserAppPermissions: boolean = this.authService.hasUserAppPermissions();
                const hasUserAcceptedTermsOfUse: boolean = currentUser?.areTermsOfUseAccepted;

                const isChatAssistantEnabled: boolean =
                    this.featureFlagsService.isFeatureEnabled('chatAssistant') &&
                    (environment.app !== 'd3' ||
                        currentUser?.departments.some(
                            ({ name }: UserGroup) => name === DMI_DEPARTMENT_NAME,
                        ));

                if (isUserAuthenticated) {
                    if (!this.refreshTokenService.getTimer()) {
                        this.refreshTokenService.setTimer();
                    }

                    if (!this.refreshTokenService.getFocusListener()) {
                        this.refreshTokenService.setFocusListener();
                    }

                    if (hasUserAppPermissions && hasUserAcceptedTermsOfUse) {
                        return true;
                    }

                    if (!hasUserAppPermissions) {
                        this.router.navigate(['/auth/permissions']);
                        return false;
                    }

                    if (!hasUserAcceptedTermsOfUse) {
                        this.router.navigate(['/legal/terms-of-service'], {
                            queryParams: { shouldAcceptTerms: true },
                        });
                        return false;
                    }
                }

                if (isChatAssistantEnabled) {
                    this.gptFacade.deleteAssistantQueries();
                }

                this.authFacade.logout([TokenTypes.access, TokenTypes.refresh]);

                if (this.featureFlagsService.isFeatureEnabled('notebooks')) {
                    this.notebookServersFacade.getNotebookServers();
                    const connectionsSubscription: Subscription = this.connections$
                        .pipe(
                            filter(
                                (connections: number | boolean) => typeof connections === 'number',
                            ),
                        )
                        .subscribe((connections: number) => {
                            if (connections > 0) {
                                this.logoutNotebookServer();
                            }
                        });

                    connectionsSubscription.unsubscribe();
                }

                this.router.navigate(['/auth/login']);
                return false;
            }),
        );
    }

    /**
     * Removes XSRF-TOKEN cookie, closes open notebook server connections and
     * logouts from the jupyterHub.
     */
    logoutNotebookServer(): void {
        this.notebookServersFacade.deleteNotebookServer();
        document.cookie = `XSRF-TOKEN=; Path=/; Domain=.${environment.domain}; Max-Age=0`;
        this.notebookServersFacade.logoutJupyterHub();
    }
}
