import { Injectable } from '@angular/core';
import PaginatedPatentsRestApi from '../rest-api-interfaces/paginated-patents.rest.interface';
import PaginatedPatents from '../interfaces/paginated-patents.interface';
import PatentRestApi from '../rest-api-interfaces/patent.rest.interface';
import Patent from '../interfaces/patent.interface';

@Injectable()
export class PatentsParser {
    constructor() {}

    /** Parses Paginated Patents */
    parsePaginatedResults(paginatedPatents: PaginatedPatentsRestApi): PaginatedPatents {
        return {
            results: paginatedPatents.results ? this.parsePatents(paginatedPatents.results) : [],
            pageIndex: paginatedPatents.pageIndex,
            pageSize: paginatedPatents.pageSize,
            total: paginatedPatents.total,
            oldestPublicationDate: paginatedPatents.oldestPublicationYear,
            newestPublicationDate: paginatedPatents.newestPublicationYear,
        };
    }

    parsePatents(patents: PatentRestApi[]): Patent[] {
        return patents.map((patent: PatentRestApi) => this.parsePatent(patent));
    }

    parsePatent(patent: PatentRestApi): Patent {
        return {
            id: patent.patent,
            publicationDate: patent.publication_date,
            title: patent.title,
            url: patent.link,
        };
    }
}
