import { environment } from '@environments/leap/environment';
import { iconColor, backgroundColor } from '@leap-common/themes/header';
import Header from '@leap-common/interfaces/header.interface';

export const PRIVACY_POLICY_HEADER: Header = {
    title: 'Privacy policy',
    icon: {
        backgroundColor: backgroundColor(),
        color: iconColor(environment.organization),
        class: 'fa-solid fa-shield-alt',
    },
};
export const PRIVACY_POLICY_FILENAME: string = 'privacy-policy.html';
export const PRIVACY_POLICY_PATH: string = `assets/docs/${environment.app}/${PRIVACY_POLICY_FILENAME}`;
export const PRIVACY_POLICY_LOADING_TEXT: string = 'Loading privacy policy...';

export const TERMS_OF_USE_HEADER: Header = {
    title: 'Terms of service',
    icon: {
        backgroundColor: backgroundColor(),
        color: iconColor(environment.organization),
        class: 'fa-solid fa-file-signature',
    },
};
export const TERMS_OF_USE_FILENAME: string = 'terms-of-use.html';
export const TERMS_OF_USE_PATH: string = `assets/docs/${environment.app}/${TERMS_OF_USE_FILENAME}`;
export const TERMS_OF_USE_LOADING_TEXT: string = 'Loading terms of service...';

export const ACCEPT_TERMS_OF_USE_BUTTON_TEXT: string = 'Accept the terms of service and login';
