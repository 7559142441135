/** third-party imports */
import { Injectable } from '@angular/core';
import { flatten } from 'lodash';

/** Parsers */
import { ReportParser } from '@leap-store/core/src/lib/data/report/parsers/report.parser';
import { CompoundReportParser } from '@leap-store/core/src/lib/data/compound-report/parsers/compound-report.parser';

/** Interfaces - Enums */
import Overview from '../interfaces/overview.interface';
import HealthLabelDescription from '../interfaces/health-label-description.interface';
import RelationshipGroup from '../enums/relationship-group.enum';
import RelationshipGroupStatisticsItemRestApi from '../../compound-report/rest-api-interfaces/relationship-group-statistics-item.rest.interface';
import HealthLabelInfoRestApi from '../rest-api-interfaces/health-label-info.rest.interface';
import TopCompound from '../interfaces/top-compound.interface';
import TopCompoundRestApi from '../rest-api-interfaces/top-compound.rest.interface';
import HealthLabelStatisticsRestApi from '../rest-api-interfaces/health-label-statistics.rest.interface';
import HealthLabelStatistics from '../interfaces/health-label-statistics.interface';
import HealthLabelSummariesRestApi from '../rest-api-types/health-label-summaries.rest.type';
import HealthLabelSummaries from '../interfaces/health-label-summaries.interface';
import Summary from '../../report/interfaces/summary.interface';
import SummaryRestApi from '../../compound-report/rest-api-interfaces/summary.rest.interface';
import HealthLabelTopCompounds from '../interfaces/health-label-top-compounds.interface';
import HealthLabelTopCompoundsRestApi from '../rest-api-types/health-label-top-compounds.rest.type';
import TopTargetRestApi from '../rest-api-interfaces/top-target.rest.interface';

@Injectable()
export class ProfilerReportParser {
    constructor(
        private reportParser: ReportParser,
        private compoundReportParser: CompoundReportParser,
    ) {}

    parseOverview(healthLabelsInfo: HealthLabelInfoRestApi[]): Overview {
        return healthLabelsInfo.reduce(
            (accumulator: Overview, healthLabelInfo: HealthLabelInfoRestApi) => {
                accumulator.statistics.push({
                    healthLabel: healthLabelInfo.healthArea,
                    relationshipGroupStatistics: {
                        [RelationshipGroup.prevents]: healthLabelInfo[RelationshipGroup.prevents],
                        [RelationshipGroup.causes]: healthLabelInfo[RelationshipGroup.causes],
                    },
                });
                accumulator.descriptions.push(this.parseHealthLabelDescription(healthLabelInfo));

                return accumulator;
            },
            { statistics: [], descriptions: [] },
        );
    }

    parseHealthLabelDescription(healthLabelInfo: HealthLabelInfoRestApi): HealthLabelDescription {
        return {
            name: healthLabelInfo.healthArea,
            text: healthLabelInfo.description.summary,
            targets: healthLabelInfo.description.medicalConditions,
        };
    }

    parseHealthLabelsStatistics(
        healthLabelsStatistics: HealthLabelStatisticsRestApi[],
    ): HealthLabelStatistics[] {
        return healthLabelsStatistics?.map(this.parseHealthLabelStatistics) || [];
    }

    parseHealthLabelStatistics(
        healthLabelStatistics: HealthLabelStatisticsRestApi,
    ): HealthLabelStatistics {
        return {
            name: healthLabelStatistics.healthArea,
            targetsCount: healthLabelStatistics.noOfAssociations,
            filteredTargetsCount: healthLabelStatistics.noOfFilteredAssociations,
            relationshipGroups: healthLabelStatistics.counts
                .filter(
                    ({ relationshipTypeGroup }: RelationshipGroupStatisticsItemRestApi) =>
                        !relationshipTypeGroup.includes(RelationshipGroup.other),
                )
                .map(
                    ({ relationshipTypeGroup, count }: RelationshipGroupStatisticsItemRestApi) => ({
                        name: relationshipTypeGroup,
                        value: count,
                    }),
                ),
            otherRelationships: healthLabelStatistics.counts
                .filter(({ relationshipTypeGroup }: RelationshipGroupStatisticsItemRestApi) =>
                    relationshipTypeGroup.includes(RelationshipGroup.other),
                )
                .map(
                    ({ relationshipTypeGroup, count }: RelationshipGroupStatisticsItemRestApi) => ({
                        name:
                            relationshipTypeGroup === RelationshipGroup.other
                                ? relationshipTypeGroup
                                : relationshipTypeGroup.split(' ').slice(1).join(' '),
                        value: count,
                    }),
                ),
        };
    }

    parseHealthLabelSummaries(
        healthLabel: string,
        healthLabelSummaries: HealthLabelSummariesRestApi,
    ): HealthLabelSummaries {
        return {
            healthLabel,
            summaryPerRelationshipGroup: healthLabelSummaries
                ? Object.entries(healthLabelSummaries).reduce(
                      (
                          accumulator: Record<RelationshipGroup, Summary>,
                          [relationshipGroup, summary]: [RelationshipGroup, SummaryRestApi],
                      ) => {
                          accumulator[relationshipGroup] =
                              this.compoundReportParser.parseSummary(summary);
                          return accumulator;
                      },
                      {} as Record<RelationshipGroup, Summary>,
                  )
                : null,
        };
    }

    parseHealthLabelTopCompounds(
        healthLabel: string,
        healthLabelTopCompounds: HealthLabelTopCompoundsRestApi,
    ): HealthLabelTopCompounds {
        return {
            healthLabel,
            topCompoundsPerRelationshipGroup: healthLabelTopCompounds
                ? Object.entries(healthLabelTopCompounds).reduce(
                      (
                          accumulator: Record<RelationshipGroup, TopCompound[]>,
                          [relationshipGroup, topCompounds]: [
                              RelationshipGroup,
                              TopCompoundRestApi[],
                          ],
                      ) => {
                          accumulator[relationshipGroup] = this.parseTopCompounds(topCompounds);
                          return accumulator;
                      },
                      {} as Record<RelationshipGroup, TopCompound[]>,
                  )
                : null,
        };
    }

    parseTopCompounds(topCompounds: TopCompoundRestApi[]): TopCompound[] {
        return topCompounds.map((topCompound: TopCompoundRestApi) =>
            this.parseTopCompound(topCompound),
        );
    }

    parseTopCompound(topCompound: TopCompoundRestApi): TopCompound {
        return {
            name: topCompound.moleculeName,
            rankingIndex: topCompound.index,
            cowMilkConcentration: topCompound.cowMilkConcentration,
            sourceConcentration: topCompound.sourceConcentrations,
            relationships: topCompound.relationshipTypes,
            healthLabels: topCompound.healthAreas,
            references: this.reportParser.parseReferences(
                flatten(
                    topCompound.topMedicalTerms.map(
                        ({ references }: TopTargetRestApi) => references,
                    ),
                ),
            ),
            topTargets: topCompound.topMedicalTerms.map(({ name }: TopTargetRestApi) => name),
        };
    }
}
