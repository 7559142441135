/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { ArticlesParser } from '../parsers/articles.parser';

/** Interfaces */
import PaginatedArticles from '../interfaces/paginated-articles.interface';
import PaginatedArticlesRestApi from '../rest-api-interfaces/paginated-articles.rest.interface';
import ResultsRestApi from '@leap-common/rest-api-interfaces/results.rest.interface';
import ArticleInfoRestApi from '../rest-api-interfaces/article-info.rest.interface';
import ArticleInfo from '../interfaces/article-info.interface';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';

@Injectable()
export class ArticlesService {
    constructor(private http: HttpClient, private articlesParser: ArticlesParser) {}

    getArticlesInfo(ids: [string, string, string?][]): Observable<ArticleInfo[]> {
        return this.http
            .post(`${environment.discoveryServerUrl}/relevant-articles/info`, {
                cuiTuples: ids,
            })
            .pipe(
                map((articlesInfo: ResultsRestApi<ArticleInfoRestApi>) =>
                    this.articlesParser.parseArticlesInfo(articlesInfo.results),
                ),
            );
    }

    getArticles(
        ids: [string, string, string?],
        startYear: number,
        endYear: number,
        pageSize: number,
        pageIndex: number,
        studyTypes: string[],
        journals: string[],
        origins: string[],
        relationshipTypes: string[],
        sortingOptions: SortingOptions,
        areResultsHighlighted: boolean,
        areResultsFullTextOnly: boolean,
        areResultsCoOccurrencesOnly: boolean,
        searchQuery?: string,
    ): Observable<PaginatedArticles> {
        return this.http
            .post(
                `${environment.discoveryServerUrl}/relevant-articles/details${
                    areResultsHighlighted ? '' : '?ignoreSpans'
                }`,
                {
                    cuiTuple: ids,
                    fromYear: startYear,
                    toYear: endYear,
                    includedTypesOfStudy: studyTypes,
                    includedJournals: journals,
                    includedSources: origins,
                    includedRelationshipTypes: relationshipTypes,
                    sortingBy: sortingOptions.field,
                    sortingOrder: sortingOptions.order,
                    pageSize,
                    pageIndex,
                    fullTextOnly: areResultsFullTextOnly,
                    coOccurrencesOnly: areResultsCoOccurrencesOnly,
                    ...(searchQuery && { phrase: searchQuery }),
                },
            )
            .pipe(
                map((paginatedResults: PaginatedArticlesRestApi) =>
                    this.articlesParser.parsePaginatedArticles(paginatedResults),
                ),
            );
    }

    downloadArticles(
        ids: [string, string, string?],
        startYear: number,
        endYear: number,
        areResultsFullTextOnly: boolean,
        areResultsCoOccurrencesOnly: boolean,
        studyTypes: string[],
        journals: string[],
        origins: string[],
        relationshipTypes: string[],
        searchQuery: string,
        sortingOptions?: SortingOptions,
        pageSize?: number,
        pageIndex?: number,
    ): Observable<Blob> {
        return this.http.post(
            `${environment.discoveryServerUrl}/relevant-articles/details/download`,
            {
                cuiTuple: ids,
                fromYear: startYear,
                toYear: endYear,
                fullTextOnly: areResultsFullTextOnly,
                coOccurrencesOnly: areResultsCoOccurrencesOnly,
                includedTypesOfStudy: studyTypes,
                includedJournals: journals,
                includedSources: origins,
                includedRelationshipTypes: relationshipTypes,
                sortingBy: sortingOptions?.field,
                sortingOrder: sortingOptions?.order,
                ...(pageSize && { pageSize }),
                ...(pageIndex && { pageIndex }),
                ...(searchQuery && { phrase: searchQuery }),
            },
            {
                headers: new HttpHeaders({
                    Accept: 'text/csv',
                }),
                responseType: 'blob',
            },
        );
    }

    downloadFullArticle(
        id: string,
        selectedTerm?: string,
        sourceName?: string,
        targetName?: string,
    ): Observable<Blob> {
        const discoveryTerms: Record<string, string> = (sourceName || targetName) && {
            ...(sourceName ? { sourceTerm: sourceName } : {}),
            ...(targetName ? { targetTerm: targetName } : {}),
        };

        return this.http.post(
            `${environment.discoveryServerUrl}/articles/download`,
            {
                externalId: id,
                discoveryTerms,
                selectedTerm,
            },
            {
                headers: new HttpHeaders({
                    Accept: 'application/pdf',
                }),
                responseType: 'blob',
            },
        );
    }
}
