import { environment } from '@environments/leap/environment';

export const EMAIL_ICON: string = 'fa-solid fa-at';
export const PASSWORD_ICON: string = 'fa-regular fa-lock-keyhole';
export const INPUT_SHOWN_ICON: string = 'fa-regular fa-eye';
export const INPUT_HIDDEN_ICON: string = 'fa-regular fa-eye-slash';

export const EMAIL_LABEL: string = 'Email address';
export const PASSWORD_LABEL: string = 'Password';
export const NEW_PASSWORD_LABEL: string = 'New password';
export const CONFIRM_NEW_PASSWORD_LABEL: string = 'Confirm new password';
export const EMAIL_PLACEHOLDER: string = 'Type your email address';
export const PASSWORD_PLACEHOLDER: string = 'Type your password';

export const WELCOME_TEXT: string = 'Welcome to';
export const LOGIN_INTRODUCTION_TEXT: string = 'Login to your account to begin your discovery';
export const CUSTOM_LOGIN_INTRODUCTION_TEXT: string =
    environment.organization === 'mr' || environment.organization === 'mr-plant'
        ? 'The world we want tomorrow, starts with the discoveries we make today.'
        : 'Connected science for rapid R&D';

export const FORGOT_PASSWORD_TEXT: string = 'Forgot your password?';
export const FORGOT_PASSWORD_SUBTITLE: string = 'We will send you an email to reset your password.';

export const RESET_PASSWORD_TEXT: string = 'Password reset';
export const RESET_PASSWORD_SUBTITLE: string =
    'Instructions to reset your password have been sent to';

export const PERMISSIONS_TEXT: string = `You don't have permission\nto access this app.`;

export const LOGOUT_TEXT: string = 'You are logged out';
export const LOGOUT_SUBTITLE: string = `Thank you for using ${environment.product}.\nLogin again to begin your discovery.`;

export const SUBMIT_TEXT: string = 'Submit';
export const LOGIN_TEXT: string = 'Login';
export const LOGIN_AGAIN_TEXT: string = 'Login again';
export const BACK_TEXT: string = 'Go Back';
export const BACK_TO_LOGIN_TEXT: string = 'Back to login';

export const TERMS_OF_USE_TEXT: string = 'Terms of Service';
export const PRIVACY_POLICY_TEXT: string = 'Privacy Policy';
