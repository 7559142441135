/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

/** custom imports */
import { ArticlesService } from './services/articles.service';
import { BookmarksService } from '@leap-store/core/src/lib/data/bookmarks/services/bookmarks.service';
import * as actions from './articles.actions';
import ArticleInfo from './interfaces/article-info.interface';
import PaginatedArticles from './interfaces/paginated-articles.interface';

@Injectable()
export class ArticlesEffects {
    constructor(
        private actions$: Actions,
        private articlesService: ArticlesService,
        private bookmarksService: BookmarksService,
    ) {}

    getArticlesInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getArticlesInfoRequest),
            switchMap(({ ids, isSelected, discovery, bookmarkIds }) =>
                (bookmarkIds
                    ? this.bookmarksService.getArticlesInfo(bookmarkIds, ids)
                    : this.articlesService.getArticlesInfo(ids)
                ).pipe(
                    map((articlesInfo: ArticleInfo[]) =>
                        actions.getArticlesInfoSuccess({
                            ids,
                            articlesInfo,
                            isSelected,
                            discovery,
                        }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(
                            actions.getArticlesInfoFailure({
                                errorResponse,
                                entity: 'articles-info',
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    getArticles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getArticlesRequest),
            switchMap(
                ({
                    ids,
                    startYear,
                    endYear,
                    pageSize,
                    pageIndex,
                    searchQuery,
                    studyTypes,
                    journals,
                    origins,
                    relationshipTypes,
                    sortingOptions,
                    areResultsFullTextOnly,
                    areResultsCoOccurrencesOnly,
                    areResultsHighlighted,
                    bookmarkIds,
                }) =>
                    (bookmarkIds
                        ? this.bookmarksService.getArticles(
                              bookmarkIds,
                              ids,
                              startYear,
                              endYear,
                              pageSize,
                              pageIndex,
                              studyTypes,
                              journals,
                              origins,
                              relationshipTypes,
                              sortingOptions,
                              areResultsHighlighted,
                              areResultsFullTextOnly,
                              areResultsCoOccurrencesOnly,
                              searchQuery,
                          )
                        : this.articlesService.getArticles(
                              ids,
                              startYear,
                              endYear,
                              pageSize,
                              pageIndex,
                              studyTypes,
                              journals,
                              origins,
                              relationshipTypes,
                              sortingOptions,
                              areResultsHighlighted,
                              areResultsFullTextOnly,
                              areResultsCoOccurrencesOnly,
                              searchQuery,
                          )
                    ).pipe(
                        map((paginatedArticles: PaginatedArticles) =>
                            actions.getArticlesSuccess({ paginatedArticles }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.getArticlesFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    downloadArticles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.downloadArticlesRequest),
            switchMap(
                ({
                    ids,
                    startYear,
                    endYear,
                    pageSize,
                    pageIndex,
                    studyTypes,
                    journals,
                    origins,
                    relationshipTypes,
                    searchQuery,
                    sortingOptions,
                    areResultsFullTextOnly,
                    areResultsCoOccurrencesOnly,
                    bookmarkIds,
                }) =>
                    (bookmarkIds
                        ? this.bookmarksService.downloadArticles(
                              bookmarkIds,
                              ids,
                              startYear,
                              endYear,
                              areResultsFullTextOnly,
                              areResultsCoOccurrencesOnly,
                              studyTypes,
                              journals,
                              origins,
                              relationshipTypes,
                              searchQuery,
                              sortingOptions,
                              pageSize,
                              pageIndex,
                          )
                        : this.articlesService.downloadArticles(
                              ids,
                              startYear,
                              endYear,
                              areResultsFullTextOnly,
                              areResultsCoOccurrencesOnly,
                              studyTypes,
                              journals,
                              origins,
                              relationshipTypes,
                              searchQuery,
                              sortingOptions,
                              pageSize,
                              pageIndex,
                          )
                    ).pipe(
                        map((blob: Blob) => actions.downloadArticlesSuccess({ blob })),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.downloadArticlesFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    getTermArticles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getTermArticlesRequest),
            switchMap(
                ({
                    id,
                    pageSize,
                    pageIndex,
                    studyTypes,
                    journals,
                    origins,
                    relationshipTypes,
                    sortingOptions,
                    startYear,
                    endYear,
                    searchQuery,
                    areResultsHighlighted,
                    areResultsFullTextOnly,
                    areResultsCoOccurrencesOnly,
                    bookmarkIds,
                }) =>
                    (bookmarkIds
                        ? this.bookmarksService.getArticles(
                              bookmarkIds,
                              [id, id],
                              startYear,
                              endYear,
                              pageSize,
                              pageIndex,
                              studyTypes,
                              journals,
                              origins,
                              relationshipTypes,
                              sortingOptions,
                              areResultsHighlighted,
                              areResultsFullTextOnly,
                              areResultsCoOccurrencesOnly,
                              searchQuery,
                          )
                        : this.articlesService.getArticles(
                              [id, id],
                              startYear,
                              endYear,
                              pageSize,
                              pageIndex,
                              studyTypes,
                              journals,
                              origins,
                              relationshipTypes,
                              sortingOptions,
                              areResultsHighlighted,
                              areResultsFullTextOnly,
                              areResultsCoOccurrencesOnly,
                              searchQuery,
                          )
                    ).pipe(
                        map((paginatedTermArticles: PaginatedArticles) =>
                            actions.getTermArticlesSuccess({ paginatedTermArticles }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.getTermArticlesFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    downloadTermArticles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.downloadTermArticlesRequest),
            switchMap(
                ({
                    id,
                    pageSize,
                    pageIndex,
                    studyTypes,
                    journals,
                    origins,
                    relationshipTypes,
                    searchQuery,
                    sortingOptions,
                    startYear,
                    endYear,
                    areResultsFullTextOnly,
                    areResultsCoOccurrencesOnly,
                    bookmarkIds,
                }) =>
                    (bookmarkIds
                        ? this.bookmarksService.downloadArticles(
                              bookmarkIds,
                              [id, id],
                              startYear,
                              endYear,
                              areResultsFullTextOnly,
                              areResultsCoOccurrencesOnly,
                              studyTypes,
                              journals,
                              origins,
                              relationshipTypes,
                              searchQuery,
                              sortingOptions,
                              pageSize,
                              pageIndex,
                          )
                        : this.articlesService.downloadArticles(
                              [id, id],
                              startYear,
                              endYear,
                              areResultsFullTextOnly,
                              areResultsCoOccurrencesOnly,
                              studyTypes,
                              journals,
                              origins,
                              relationshipTypes,
                              searchQuery,
                              sortingOptions,
                              pageSize,
                              pageIndex,
                          )
                    ).pipe(
                        map((blob: Blob) => actions.downloadTermArticlesSuccess({ blob })),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.downloadTermArticlesFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    downloadFullArticle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.downloadFullArticleRequest),
            switchMap(({ id, selectedTerm, sourceName, targetName }) =>
                this.articlesService
                    .downloadFullArticle(id, selectedTerm, sourceName, targetName)
                    .pipe(
                        map((blob: Blob) => actions.downloadFullArticleSuccess({ blob })),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.downloadFullArticleFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );
}
