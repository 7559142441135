import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { environment } from '@environments/leap/environment';

@Injectable()
export class AppGuard implements CanActivate {
    constructor(private router: Router) {}

    /**
     * Activates the protected route based on the router expectedApp.
     * If the running app is not equal with the expected app re-route
     * user to the given route.
     */
    canActivate(route: ActivatedRouteSnapshot): boolean {
        const app: string = environment.product;
        const expectedApp: string = route.data.expectedApp;
        const redirectRoute: string = route.data.redirectRoute;

        if (app === expectedApp) {
            return true;
        }

        this.router.navigate([`/${redirectRoute}`]);
        return false;
    }
}
