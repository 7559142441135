export enum ArticlesActionTypes {
    GET_ARTICLES_INFO_REQUEST = '[DISCOVERY][ARTICLES] Get Articles Info Request',
    GET_ARTICLES_INFO_SUCCESS = '[DISCOVERY][ARTICLES] Get Articles Info Success',
    GET_ARTICLES_INFO_FAILURE = '[DISCOVERY][ARTICLES] Get Articles Info Failure',
    CLEAR_ARTICLES_INFO = '[DISCOVERY][ARTICLES] Clear Articles Info',
    SELECT_ARTICLE_INFO = '[DISCOVERY][ARTICLES] Select Article Info',
    CLEAR_SELECTED_ARTICLE_INFO = '[DISCOVERY][ARTICLES] Clear Selected Article Info',
    GET_ARTICLES_REQUEST = '[DISCOVERY][ARTICLES] Get Articles Request',
    GET_ARTICLES_SUCCESS = '[DISCOVERY][ARTICLES] Get Articles Success',
    GET_ARTICLES_FAILURE = '[DISCOVERY][ARTICLES] Get Articles Failure',
    DOWNLOAD_ARTICLES_REQUEST = '[DISCOVERY][ARTICLES] Download Articles Request',
    DOWNLOAD_ARTICLES_SUCCESS = '[DISCOVERY][ARTICLES] Download Articles Success',
    DOWNLOAD_ARTICLES_FAILURE = '[DISCOVERY][ARTICLES] Download Articles Failure',
    GET_TERM_ARTICLES_REQUEST = '[DISCOVERY][ARTICLES] Get Term Articles Request',
    GET_TERM_ARTICLES_SUCCESS = '[DISCOVERY][ARTICLES] Get Term Articles Success',
    GET_TERM_ARTICLES_FAILURE = '[DISCOVERY][ARTICLES] Get Term Articles Failure',
    DOWNLOAD_TERM_ARTICLES_REQUEST = '[DISCOVERY][ARTICLES] Download Term Articles Request',
    DOWNLOAD_TERM_ARTICLES_SUCCESS = '[DISCOVERY][ARTICLES] Download Term Articles Success',
    DOWNLOAD_TERM_ARTICLES_FAILURE = '[DISCOVERY][ARTICLES] Download Term Articles Failure',
    DOWNLOAD_FULL_ARTICLE_REQUEST = '[DISCOVERY][ARTICLES] Download Full Article Request',
    DOWNLOAD_FULL_ARTICLE_SUCCESS = '[DISCOVERY][ARTICLES] Download Full Article Success',
    DOWNLOAD_FULL_ARTICLE_FAILURE = '[DISCOVERY][ARTICLES] Download Full Article Failure',
    CLEAR_TERM_ARTICLES = '[DISCOVERY][ARTICLES] Clear Term Articles',
    CLEAR_COUNTS = '[DISCOVERY][ARTICLES] Clear Counts',
    CLEAR_NEXT_ERROR = '[DISCOVERY][ARTICLES] Clear Next Error',
}
